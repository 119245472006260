import { Dialog, IconButton } from '@mui/material';
import React from 'react';
import CustomButton from './CustomButton';
import Images from '../Utils/images';
import { AIT } from '../Utils/Localization';
interface Props {
  // open?: boolean
  open: boolean;
  handleClose: any;
  title?: string;
  description?: string;
  type: 'success' | 'warning' | 'delete';
  onClick: any;
  cancelBtnText: string;
  confirmBtnText: any;
  showIcon?: boolean;
}

export default function WarningDialog(props: Props) {
  const {
    title,
    description,
    type,
    open,
    cancelBtnText,
    confirmBtnText,
    handleClose,
    onClick,
    showIcon = false,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="dialogWrapper warning txt_center">
        <div className="dialogHeader">
          {showIcon && (
            <img
              src={
                type === 'success'
                  ? Images.SUCCESS_IC
                  : type === 'warning'
                  ? Images.WARNING
                  : type === 'delete'
                  ? Images.ERROR_IC
                  : Images.WARNING
              }
              alt={AIT().icon}
            />
          )}

          <h4
            className="small"
            style={{
              paddingTop: showIcon ? 'auto' : '20px',
            }}
          >
            {title}
          </h4>

          {/* <IconButton onClick={handleClose}>
              <img src={Images.CLOSE} alt="Close" />
            </IconButton> */}
        </div>
        <div className="doalogBody small">
          <p className="small">{description}</p>
        </div>
        <div className="dialogActions">
          <CustomButton
            size="large"
            variant="contained"
            text={cancelBtnText}
            showIcon={false}
            width="100%"
            type="submit"
            id="accept"
            loading={false}
            onClick={handleClose}
          />

          <CustomButton
            size="large"
            variant="outlined"
            text={confirmBtnText}
            showIcon={false}
            width="100%"
            type="submit"
            id="accept"
            loading={false}
            onClick={onClick}
          />
        </div>
      </div>
    </Dialog>
  );
}
