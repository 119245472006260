import { Slide, ToastOptions, toast } from 'react-toastify';
import { tranlate } from '../LocalizationJson/i18';

const ToastConfig: ToastOptions = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light',
  transition: Slide,
};

export const notify = (
  message: string | JSX.Element | null,
  type: string = 'success',
) => {
  toast.dismiss();
  if (message === null) [(message = tranlate(`Common.msgs.err.stadard`))];
  if (type === 'error') {
    toast.error(message, ToastConfig);
  } else if (type === 'success') {
    toast.success(message, ToastConfig);
  }
};
