import store from './store';
import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import { getApiCall } from '../api/methods';
import { ApiStatus, CommonState } from '../types';

const initialState: CommonState = {
  countryList: [],
  departmentList: [],
  categories: [],
  // state to block route
  blockNavigation: false,
  loading: false,
  menu: false,
  mobileChat: false,
  actionApiStatus: ApiStatus.idle,
  activeMenu: 'Dashboard',
  ///This page is  used for pagination across app
  page: 1,
  searchedLocations: [],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setData: (state, action) => {
      const {
        payload: { type, value },
      }: any = action;
      if (type === 'country') {
        state.countryList = value;
      } else if (type === 'department') {
        state.departmentList = value;
        state.categories = [];
      } else if (type === 'categories') {
        state.categories = value;
      }
    },
    setCommonDataLoader: (state, action) => {
      const { payload }: any = action;
      state.loading = payload;
    },
    setCommonMenu: (state, action) => {
      const { payload }: any = action;
      state.menu = payload;
    },
    viewChatMobile: (state, action) => {
      const { payload }: any = action;
      state.mobileChat = payload;
    },
    updateActionApiStatus: (state, action) => {
      const { payload }: any = action;
      state.actionApiStatus = payload;
    },
    updateActiveMenu: (state, action) => {
      const { payload }: any = action;
      state.activeMenu = payload;
    },
    updatePage: (state, action) => {
      const { payload }: any = action;
      state.page = payload;
    },
    setBlockNavigate: (state, action) => {
      const { payload }: any = action;
      state.blockNavigation = payload;
    },
    updateSearchedLocations: (state, action) => {
      const { payload }: any = action;
      state.searchedLocations = payload;
    },
  },
});

export const {
  setData,
  setCommonDataLoader,
  setCommonMenu,
  updateActionApiStatus,
  updateActiveMenu,
  updatePage,
  setBlockNavigate,
  viewChatMobile,
  updateSearchedLocations,
} = commonSlice.actions;

export default commonSlice.reducer;

export const getAndSetData = async (type: string, query: string = '') => {
  const { dispatch } = store;
  const apiEndpoint =
    type === 'country'
      ? endPoints.common.countryList
      : type === 'department'
      ? endPoints.common.deparmentList
      : endPoints.common.categories;

  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}${query}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setData({ type: type, value: data.data }));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      dispatch(setCommonDataLoader(false));
      notify(`Error while fetching ${type} list`, 'error');
    },
  );
};

export const updatePaginationPage = (page: number) => {
  const { dispatch } = store;
  dispatch(updatePage(page));
};

export async function fetchLocationByText(location: string) {
  const { dispatch } = store;
  console.log(location, 'loc');

  const apiKey = process.env.GOOGLE_API_KEY;
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${apiKey}`,
  );
  response.json().then((data) => {
    if (data.results) {
      dispatch(updateSearchedLocations(data.results));
      console.log(data);
    }
  });
}
