import store, { RootState } from './store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ORDER_PRODUCT_TYPES, ORDER_STATUS } from '../Utils/constantData';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import { OrderSortKeys, OrderState } from '../types';
import {
  apiSuccessCode,
  deleteApiCall,
  getApiCall,
  patchApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import { logout } from './auth.slice';
import { setCommonDataLoader } from './common.slice';
import { setProductLoader } from './products.slice';
import dayjs from 'dayjs';

const initialState: OrderState = {
  orders: [],
  orderDetailsLoader: false,
  error: false,
  page: 1,
  filter: null,
  searchKeywords: '',
  productOrderList: null,
  rentProdctsList: [],
  worksheetDetails: null,
  orderDetails: null,
  setNameList: [],
  episodeNameList: [],
  locationList: [],
  editedItem: null,
  cancleOrderModalData: null,
  addToOrderType: null,
  deliveryAddressModalData: null,
  draftDeliveryAddressModalState: false,
  listLoader: {
    setNames: false,
    locations: false,
    updateAddress: false,
  },
  rentalModalDetails: {
    state: false,
    itemDetails: null,
    cartId: '',
    rentPriceValue: '',
    rentalDetails: null,
  },
  quotationShifts: null,
  qoutationCartDetails: null,
  sortKey: OrderSortKeys.updatedAt,
  sortMode: -1,
  disableSubmitForm: false,
  draftDeliveryAddressEditedId: null,
  locationModelStatus: false,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderList: (state, action) => {
      const { payload }: any = action;
      state.orders = payload;
    },
    setOrderLoader: (state, action) => {
      const { payload }: any = action;
      state.orderDetailsLoader = payload;
    },
    setOrderError: (state, action) => {
      const { payload }: any = action;
      state.error = payload;
    },
    setPage: (state, action) => {
      const { payload }: any = action;
      state.error = payload;
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action;
      state.searchKeywords = payload;
    },
    setFilters: (state, action) => {
      const { payload }: any = action;
      state.filter = payload;
    },
    setOrderProductList: (state, action) => {
      const { payload }: any = action;
      state.productOrderList = payload;
    },
    setRentOrderProductList: (state, action) => {
      const { payload }: any = action;
      state.rentProdctsList = payload;
    },
    setWorkSheetDetails: (state, action) => {
      const { payload }: any = action;
      state.worksheetDetails = payload;
    },
    setOrderDetails: (state, action) => {
      const { payload }: any = action;
      state.orderDetails = payload;
    },
    setNameList: (state, action) => {
      const { payload }: any = action;
      state.setNameList = payload;
    },
    setEpisodeNameList: (state, action) => {
      const { payload }: any = action;
      state.episodeNameList = payload;
    },
    setLocationList: (state, action) => {
      const { payload }: any = action;
      state.locationList = payload;
    },
    // updateAddress: (state, action) => {
    //   const { payload }: any = action
    //   state.addressUpdate = payload
    // },
    setEditedItem: (state, action) => {
      const { payload }: any = action;
      state.editedItem = payload;
    },
    setCancelModalData: (
      state,
      action: PayloadAction<{
        isOrderCancle: boolean;
        orderDetails?: any;
      } | null>,
    ) => {
      const { payload } = action;
      state.cancleOrderModalData = payload;
    },
    setDeliveryAddressModalStatus: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.deliveryAddressModalData = payload;
    },
    updateAddToOrderType: (state, action) => {
      const { payload }: any = action;
      state.addToOrderType = payload;
    },
    updateListLoader: (state, action) => {
      const {
        payload: { type, value },
      }: any = action;
      state.listLoader[type] = value;
    },
    updateRentalModalData: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.rentalModalDetails = payload;
    },
    setQuotationShifts: (state, action) => {
      const { payload }: any = action;
      state.quotationShifts = payload;
    },
    updateQoutationDetails: (state, action) => {
      const { payload }: any = action;
      state.qoutationCartDetails = payload;
    },
    updateSortKey: (state, action) => {
      const { payload }: any = action;
      state.sortKey = payload;
    },
    updateSortMode: (state, action) => {
      const { payload }: any = action;
      state.sortMode = payload;
    },
    setSubmitFormDisable: (state, action) => {
      const { payload }: any = action;
      state.disableSubmitForm = payload;
    },
    setDraftAddressModalState: (state, action) => {
      const { payload }: any = action;
      state.draftDeliveryAddressModalState = payload;
    },
    setDraftAddressEditedId: (state, action) => {
      const { payload }: any = action;
      state.draftDeliveryAddressEditedId = payload;
    },
    setLocationModel: (state, action) => {
      const { payload }: any = action;
      state.locationModelStatus = payload;
    },
  },
});

export const {
  setOrderList,
  setOrderLoader,
  setOrderError,
  setPage,
  setSearchKeywords,
  setFilters,
  setOrderProductList,
  setRentOrderProductList,
  setWorkSheetDetails,
  setOrderDetails,
  setNameList,
  setEpisodeNameList,
  setLocationList,
  // updatePersonalAddress,
  // updateAddress,
  setEditedItem: setEditedItemStore,
  setCancelModalData,
  setDeliveryAddressModalStatus,
  updateAddToOrderType,
  updateListLoader,
  updateRentalModalData,
  setQuotationShifts,
  updateQoutationDetails,
  updateSortKey,
  updateSortMode,
  setSubmitFormDisable,
  setDraftAddressEditedId,
  setDraftAddressModalState,
  setLocationModel,
} = orderSlice.actions;

export default orderSlice.reducer;

const handleError = (e: any) => {
  const { dispatch } = store;

  if (e.data && e.data.message) {
    notify(e.data.message, 'error');
  }
  if (e.data && e.data.statusCode === 401) {
    logout();
  }
};

export const getAndSetOrderList = (
  query: string = '?pageNo=1&limit=10',
  branchId: string | null = null,
): any => {
  const { dispatch, getState } = store;
  const {
    order: { searchKeywords, filter: filters, sortKey, sortMode },
  }: RootState = getState();
  const searchQuery = searchKeywords
    ? `&searchKey=${encodeURIComponent(searchKeywords)}`
    : '';
  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate() + 2);
      console.log(toDate);
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.status) {
      filters.status.forEach((status: any) => {
        filterQuery += `&status=${status.value}`;
      });
    }
  }
  if (branchId) {
    filterQuery += `&branchId=${branchId}`;
  }
  filterQuery += `&sortBy=${sortKey}&sortOrder=${sortMode}`;
  const params = `${query}${searchQuery}${filterQuery}`;
  const apiEndpoint = endPoints.order.list;
  dispatch(setOrderLoader(true));
  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setOrderList(data.data));
      }
      dispatch(setOrderLoader(false));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(setOrderLoader(false));
    },
    branchId ? { ['branch_id']: branchId } : {},
  );
};

export const getAndSetDraftOrderList = (
  query: string = '?pageNo=1&limit=10',
  branchId: string | null = null,
): any => {
  const { dispatch, getState } = store;
  const {
    order: { searchKeywords, filter: filters, sortKey, sortMode },
  }: RootState = getState();
  const searchQuery = searchKeywords
    ? `&searchKey=${encodeURIComponent(searchKeywords)}`
    : '';
  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate() + 2);
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.status) {
      filters.status.forEach((status: any) => {
        filterQuery += `&status=${status.value}`;
      });
    }
  }
  if (branchId) {
    filterQuery += `&branchId=${branchId}`;
  }
  filterQuery += `&sortBy=${sortKey}&sortOrder=${sortMode}`;
  const params = `${query}${searchQuery}${filterQuery}`;
  dispatch(setOrderLoader(true));
  getApiCall(
    `${endPoints.order.draft.CRUD}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setOrderList(data.data));
      }
      dispatch(setOrderLoader(false));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(setOrderLoader(false));
    },
    branchId ? { ['branch_id']: branchId } : {},
  );
};

export const getOrderProductsList = (
  vendorId: string,
  productType: string | null = null,
  pagination: string | null = null,
) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  let query = `?vendorOrderId=${vendorId}`;
  if (productType) {
    query += `&shoppingMode=${productType}`;
  }
  if (pagination) {
    query += `&pagination=true&${pagination}`;
  }

  getApiCall(
    `${endPoints.order.productItems}${query}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        if (productType === ORDER_PRODUCT_TYPES.buy) {
          dispatch(setOrderProductList(data.data));
        } else if (productType === ORDER_PRODUCT_TYPES.rent) {
          dispatch(setRentOrderProductList(data.data));
        } else {
          dispatch(setOrderProductList(data.data));
        }
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getAndOrderDetails = (orderId: string) => {
  const { dispatch } = store;
  dispatch(setOrderLoader(true));
  getApiCall(
    `${endPoints.order.list}/${orderId}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data && data.data[0]) {
        dispatch(setOrderDetails(data.data[0]));
      }
      dispatch(setOrderLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setOrderLoader(false));
    },
  );
};

export const getDraftDetails = (orderId: string, loader: boolean = true) => {
  const { dispatch } = store;
  loader && dispatch(setOrderLoader(true));
  getApiCall(
    `${endPoints.order.draft.CRUD}/${orderId}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data && data.data) {
        dispatch(setOrderDetails(data.data));
      } else {
        dispatch(setOrderDetails(null));
      }
      dispatch(setOrderLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setOrderLoader(false));
    },
  );
};

export const getSetNameList = (productionId: string, callBack: Function) => {
  const { dispatch } = store;
  dispatch(updateListLoader({ type: 'setNames', value: true }));
  getApiCall(
    `${endPoints.order.list}/set?productionId=${productionId}`,
    (s: any) => {
      const { data } = s?.data;
      if (data) {
        if (data?.setList && data?.setList?.length) {
          dispatch(setNameList(data?.setList));
        }
        if (data?.episodeList && data?.episodeList?.length) {
          dispatch(setEpisodeNameList(data?.episodeList));
        }
        callBack();
      }
      dispatch(updateListLoader({ type: 'setNames', value: false }));
    },

    (e: any) => {
      handleError(e);
      dispatch(updateListLoader({ type: 'setNames', value: false }));
    },
  );
};

export const getSetLocationList = (productionId: string, setId: string) => {
  const { dispatch } = store;
  dispatch(updateListLoader({ type: 'locations', value: true }));

  getApiCall(
    `${endPoints.order.list}/set/locations?productionId=${productionId}&setId=${setId}`,
    (s: any) => {
      const {
        data: { data },
      } = s;

      if (data && data.setLocationList) {
        dispatch(setLocationList(data.setLocationList));
      }
      dispatch(updateListLoader({ type: 'locations', value: false }));
    },
    (e: any) => {
      handleError(e);
      dispatch(updateListLoader({ type: 'locations', value: false }));
    },
  );
};

export const cancleOrder = (data: any, cb: (data: boolean) => void) => {
  const { dispatch, getState } = store;
  const {
    order: { cancleOrderModalData, editedItem },
  }: RootState = getState();
  dispatch(setCommonDataLoader(true));
  const cloneData = { ...data };
  if (!cancleOrderModalData?.isOrderCancle && editedItem) {
    cloneData['orderId'] = editedItem.product._id;
  } else if (cancleOrderModalData) {
    const { orderDetails } = cancleOrderModalData;
    cloneData['vendorOrderId'] = orderDetails.vendorOrderId;
  }
  if (cloneData['cancelNotes'] && cloneData['cancelNotes'].trim() == '') {
    delete cloneData['cancelNotes'];
  }
  postApiCall(
    endPoints.order.cancleOrder,
    cloneData,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success) {
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(true));
    },
  );
};

export const updateQuantity = (data: any, cb: any) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  putApiCall(
    endPoints.order.updateQuantity,
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success) {
        notify('Qty Updated Successfully.', 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb && cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const updateRentQuantity = (data: any, cb: any) => {
  let { qty, id } = data;
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  putApiCall(
    endPoints.order.updateRentQuantity(id),
    { qty },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (id && statusCode && statusCode === apiSuccessCode.accepted) {
        notify('Qty Updated Successfully.', 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const rentApproveReject = (data: any, cb: any) => {
  const { dispatch, getState } = store;
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState();
  dispatch(setCommonDataLoader(true));
  const { id, status, reason, note } = data;

  putApiCall(
    endPoints.order.rentApproveReject + id + '/accept-reject',
    { status, ...(reason && { reason }), ...(note && { note }) },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success) {
        notify(message, 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const updateStatus = (data: any, cb?: any, errorCB?: any) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  patchApiCall(
    endPoints.order.updateStatus,
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success) {
        if (errorCB) {
          notify('Processing, It will take some time.', 'success');
        } else {
          notify(message, 'success');
        }
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      errorCB && errorCB();
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const updateAddressCall = (data: any, cb: any) => {
  const { dispatch } = store;
  dispatch(updateListLoader({ type: 'updateAddress', value: true }));

  patchApiCall(
    endPoints.order.updateAddress,
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;

      if (data && statusCode && statusCode === apiSuccessCode.success) {
        notify('Address update successfully', 'success');
        cb(true);
      }
      dispatch(updateListLoader({ type: 'updateAddress', value: false }));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(updateListLoader({ type: 'updateAddress', value: false }));
    },
  );
};

export const addProductsToOrder = (
  cb: (flag: boolean) => void,
  isCustomOrder: boolean = false,
) => {
  const { dispatch, getState } = store;
  const {
    chat: { selectedIds, productsAndServiceList },
    order: { orderDetails },
  }: RootState = getState();
  dispatch(setCommonDataLoader(true));
  const payload: any = {
    shoppingMode: 'BUY',
    products: selectedIds.map((p: any, i: number) => {
      return {
        productId: p,
        isRushApply: false,
        isPackage: Boolean(productsAndServiceList[i].packageType),
      };
    }),
  };
  // let productAndService: any = [];
  // let packages: any = [];
  // productsAndServiceList.forEach((p: any) => {
  //   let payloadP = { productId: p._id, isRushApply: false };
  //   if (p.packageType) {
  //     packages.push(payloadP);
  //   } else {
  //     productAndService.push(payloadP);
  //   }
  // });
  // payload['products'] = productAndService;
  // payload['packages'] = packages;
  console.log('isCustomOrder-->', isCustomOrder);

  if (isCustomOrder) {
    payload['isVendorCartOrder'] = true;
  }
  postApiCall(
    endPoints.order.addProducts(orderDetails._id),
    payload,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        notify('Item added successfully into order', 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};
/**
 *
 * @param cb
 * @param cartIds pass array of cart IDs  to add rent products to drafts
 */
export const addProductsToDraft = (
  cb: (flag: boolean) => void,
  cartIds: string[] = [],
) => {
  const { dispatch, getState } = store;
  const {
    // product: { selectedIds },
    order: { orderDetails },
    auth: { userData },
    chat: { selectedIds, productsAndServiceList },
  }: RootState = getState();

  dispatch(setCommonDataLoader(true));

  postApiCall(
    endPoints.order.draft.addProducts,
    cartIds.length > 0
      ? {
          shoppingMode: 'RENT',
          products: [],
          draftId: orderDetails?.draftDetail?._id,
          branchId: userData.branchId,
          cartIds,
        }
      : {
          shoppingMode: 'BUY',
          products: selectedIds.map((p, i: number) => {
            return {
              productId: p,
              isRushApply: false,
              qty: 1,
              ...(productsAndServiceList[i].packageType && { isPackage: true }),
            };
          }),
          draftId: orderDetails?.draftDetail?._id,
          branchId: userData.branchId,
          cartIds: [],
        },
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        notify('Item added successfully into order', 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const createOrderFromDraft = (cb: any) => {
  const { dispatch, getState } = store;
  const {
    order: { orderDetails },
  }: RootState = getState();
  dispatch(setCommonDataLoader(false));

  postApiCall(
    endPoints.order.draft.createOrder,
    { draftId: orderDetails?.draftDetail?._id },
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
    },
  );
};

export const deleteItemFromDraft = (cartId: string, cb: any) => {
  const { dispatch, getState } = store;
  const {
    order: { orderDetails },
  }: RootState = getState();
  dispatch(setCommonDataLoader(false));

  postApiCall(
    endPoints.order.draft.createOrder,
    { draftId: orderDetails?.draftDetail?._id },
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
    },
  );
};

export const addPersonalAddress = (data: any, cb: any) => {
  const { dispatch, getState } = store;
  postApiCall(
    endPoints.order.updateAddress,
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        notify('Address added successfully', 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
    },
  );
};

export const getCartIdDraft = (payload: any, cb: any) => {
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));
  postApiCall(
    `${endPoints.order.draft.createCartId}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (
        statusCode &&
        statusCode === apiSuccessCode.success &&
        data &&
        data.cartId
      ) {
        cb(data.cartId);
      }
      cb(false);

      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getCartId = (payload: any, cb: any) => {
  const { dispatch, getState } = store;

  dispatch(setCommonDataLoader(true));
  postApiCall(
    `${endPoints.order.createCart}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (
        statusCode &&
        statusCode === apiSuccessCode.success &&
        data &&
        data.cartId
      ) {
        cb(data.cartId);
      }
      cb(false);

      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

/**
 *
 * @param cartId
 * @param payload
 * @param cb
 */
export const updateCartRentalDetails = (
  cartId: string,
  payload: any,
  cb: any,
) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  putApiCall(
    `${endPoints.order.cartRentalDetailsUpdate(cartId)}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.accepted) {
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const addToOrderRentProducts = (payload: any, cb: any) => {
  const { dispatch, getState } = store;
  dispatch(setCommonDataLoader(true));
  postApiCall(
    `${endPoints.order.addItemServicesToOrder}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        // notify('Item added successfully into order', 'success')
        notify('Items added to order.', 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const createOrderQoute = (payload: any, cb: any) => {
  const { dispatch, getState } = store;
  dispatch(setCommonDataLoader(true));
  postApiCall(
    `${endPoints.order.quotationCart}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        // notify('Item added successfully into order', 'success')
        notify('Order is created for qoutation.', 'success');
        cb(true, data);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const updateCart = (payload: any, cb: any) => {
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));
  putApiCall(
    `${endPoints.order.updateCartOrDelete}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.accepted) {
        dispatch(setCommonDataLoader(false));

        cb(s);
      } else {
        // cb(s);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      // cb(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getWorksheetDetails = (id: string, query: string) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${endPoints.order.viewWorksheet(id)}${query}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setWorkSheetDetails(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const extendService = (data: any, cb: any) => {
  const { dispatch, getState } = store;
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState();
  // dispatch(setCommonDataLoader(true))
  const { id, startDate, endDate } = data;

  putApiCall(
    endPoints.order.extend_service(id),
    { startDate, endDate },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.accepted) {
        notify(message, 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const updateShift = (data: any, cb: any) => {
  const { dispatch, getState } = store;
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState();
  // dispatch(setCommonDataLoader(true))
  const { id, startDate, endDate } = data;

  putApiCall(
    endPoints.order.updateShift(id),
    { endDate },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.accepted) {
        notify(message, 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const updateShiftAcceptReject = (data: any, cb: any) => {
  const { dispatch, getState } = store;
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState();
  // dispatch(setCommonDataLoader(true))
  const { id, status } = data;

  putApiCall(
    endPoints.order.updateStatusShift(id),
    { status },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.accepted) {
        notify(message, 'success');
        cb(true);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getQuotationWorkSheetShifts = (cartId: string, query: string) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${endPoints.order.quotationShifts}?cartId=${cartId}${query}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setQuotationShifts(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getQuotationCartDetails = () => {
  const { dispatch, getState } = store;
  const {
    chat: { selectedQuote },
  }: RootState = getState();
  if (selectedQuote?._id) {
    dispatch(setProductLoader(true));
    getApiCall(
      `${endPoints.order.quotationCartDetails}${selectedQuote?._id}?userId=${selectedQuote.createdBy}`,
      (s: any) => {
        const { data: data } = s;
        if (data.data) {
          dispatch(updateQoutationDetails(data.data));
        }
        dispatch(setProductLoader(false));
      },
      (e: any) => {
        handleError(e);
        dispatch(setProductLoader(false));
      },
    );
  }
};

export const deleteCart = (
  query: string,
  loader: boolean = false,
  cb: any = undefined,
) => {
  const { dispatch } = store;

  loader && dispatch(setCommonDataLoader(true));
  deleteApiCall(
    `${endPoints.order.updateCartOrDelete}?${query}`,
    {},
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      loader && dispatch(setCommonDataLoader(false));
      cb && cb(true);
    },
    (e: any) => {
      // handleError(e)
      cb && cb(false);
      loader && dispatch(setCommonDataLoader(false));
    },
  );
};
export const deleteDraft = (id: string, cb: any = undefined) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  deleteApiCall(
    `${endPoints.order.draft.CRUD}/${id}`,
    {},
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      dispatch(setCommonDataLoader(false));
      cb && cb(true);
    },
    (e: any) => {
      // handleError(e)
      cb && cb(false);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const findLocationByText = async (location: string, thunkApi: any) => {
  if (!location.length) {
    return [];
  }
  try {
    const apiResult = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyCu3pQ2rxalRAWIONNcxoYST8Cy7NJrveA`,
    ).then((response) => response.json());

    if (apiResult) {
      const { status, results } = apiResult;
      if (results && results.length) {
        return results;
      }
      return [];
    }
    return [];
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
};

export const createOrder = (d: any, cb: any) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  Object.keys(d).forEach((k: string) => {
    if (!d[k]) {
      delete d[k];
    }
  });
  postApiCall(
    endPoints.order.draft.CRUD,
    d,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        cb(s);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(true));
    },
  );
};

export const updateOrder = (d: any, cb: any) => {
  const { dispatch, getState } = store;
  const {
    order: { orderDetails },
  }: RootState = getState();
  dispatch(setCommonDataLoader(true));

  putApiCall(
    `${endPoints.order.draft.CRUD}/${orderDetails?.draftDetail?._id}`,
    d,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        cb(s);
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      cb(false);
      dispatch(setCommonDataLoader(true));
    },
  );
};

export const handleLocationModel = (status: boolean) => {
  const { dispatch } = store;
  dispatch(setLocationModel(status));
};

export const getOrderStatus = (orderId: string, cb?: any) => {
  const { dispatch } = store;
  // dispatch(setOrderLoader(true));
  getApiCall(
    `${endPoints.order.getOrderStatus(orderId)}`,
    (s: any) => {
      const { data: data } = s;
      if (data) {
        // console.log('RESSSS =>', data?.data);
        // if (['STARTED', 'ENDED', 'COMPLETED'].includes(data?.data?.status)) {
        cb(data?.data?.status);
        // }
        // dispatch(setOrderDetails(data.data));
      } else {
        // dispatch(setOrderDetails(null));
      }
      // dispatch(setOrderLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setOrderLoader(false));
    },
  );
};
