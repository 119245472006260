import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../Components/CustomButton';
import { AIT } from '../../Utils/Localization';
import { FORM_STEPS } from '../../Utils/constantData';
import Images from '../../Utils/images';
import { ROUTES } from '../../helpers/contants';
import { RootState, useAppSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../redux/auth.slice';

function Welcome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData, status } = useAppSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (userData.formNextStep !== undefined) {
      navigate(ROUTES.BUSINESSINFO);
    }
  }, []);

  const { t } = useTranslation('translation', {
    keyPrefix: 'welcome',
  });
  const { t: tF } = useTranslation('translation', {
    keyPrefix: 'footer',
  });
  return status ? (
    <div className="welcomeScreen">
      <div className="wel_left">
        <div className="wel_message">
          <h1>{t('heading')}</h1>
          <ul>
            <li>
              <div className="num">1</div>
              <div className="desc">
                <h3>{t('title_1')}</h3>
                <p>{t('descp_1')}</p>
              </div>
            </li>
            <li>
              <div className="num">2</div>
              <div className="desc">
                <h3>{t('title_2')}</h3>
                <p>{t('descp_2')}</p>
              </div>
            </li>
            <li>
              <div className="num">3</div>
              <div className="desc">
                <h3>{t('title_3')}</h3>
                <p>{t('descp_3')}</p>
              </div>
            </li>
          </ul>

          <div className="burronWrapper">
            <CustomButton
              onClick={() => {
                dispatch(updateStep({ step: FORM_STEPS.BUSINESS_INFO }));
                navigate(ROUTES.BUSINESSINFO);
              }}
              size="large"
              variant="contained"
              text={t('lets_begin')}
              showIcon={false}
              width="100%"
              type="submit"
              id="login"
              loading={false}
            />
          </div>
        </div>
      </div>
      <div className="wel_right">
        <div className="infoGraphic">
          <figure>
            <img src={Images.WELCOME} alt={AIT().welcome} />
          </figure>
          <h3>{t('what_you_will_need')}</h3>
          <ul>
            <li>
              <p>{t('valid_government_issued_id_or_passport')}</p>
            </li>
            <li>
              <p>{t('recent_bank_account_or_credit_card_statement')}</p>
            </li>
            <li>
              <p>{t('chargeable_credit_or_debit_card')}</p>
            </li>
            <li>
              <p>{t('mobile_number')}</p>
            </li>
          </ul>
        </div>

        <div className="copyright">
          <p>{tF('copyright')}</p>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Welcome;
