import store, { RootState } from './store';
import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { PERMISSION_TYPES } from '../Utils/constantData';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import {
  apiSuccessCode,
  deleteApiCall,
  getApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import { ApiStatus, CoVendorState } from '../types';
import { updateActionApiStatus } from './common.slice';
import { tranlate } from '../LocalizationJson/i18';

const t = (k: string) => tranlate(`Common.${k}`);

const getNonNullModules = (table: any) => {
  const clonePermissions = { ...table };
  Object.entries(clonePermissions).forEach(([m, p]) => {
    if (p == null) {
      delete clonePermissions[m];
    }
  });
  return clonePermissions;
};

const initialState: CoVendorState = {
  apiStatus: ApiStatus.idle,
  permissionTable: {},
  moduleTable: null,
  backendModuleTable: null,
  vendors: null,
  filter: null,
  searchKeywords: '',
  coVendorDetails: null,
  vendorToBeDelete: null,
  deleteVendorModal: false,
};

const coVendorSlice = createSlice({
  name: 'coVendor',
  initialState,
  reducers: {
    updateApiStatus: (state, action) => {
      const { payload }: any = action;
      state.apiStatus = payload;
    },
    updatePermissionTable: (state, action) => {
      const { payload }: any = action;
      state.permissionTable = payload;
    },
    updateBackendPermissionTable: (state, action) => {
      const { payload }: any = action;
      state.backendModuleTable = payload;
    },
    setVendorList: (state, action) => {
      const { payload }: any = action;
      state.vendors = payload;
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action;
      state.searchKeywords = payload;
    },
    setFilters: (state, action) => {
      const { payload }: any = action;
      state.filter = payload;
    },
    setVendorDetails: (state, action) => {
      const { payload }: any = action;
      state.coVendorDetails = payload;
    },
    setVendorToBeDelete: (state, action) => {
      const { payload }: any = action;
      state.vendorToBeDelete = payload;
    },
    setDeleteModalVisibility: (state, action) => {
      const { payload }: any = action;
      if (!payload) {
        state.vendorToBeDelete = null;
      }
      state.deleteVendorModal = payload;
    },
  },
});

export const {
  updateApiStatus,
  updatePermissionTable,
  updateBackendPermissionTable,
  setVendorList,
  setSearchKeywords,
  setFilters,
  setVendorDetails,
  setVendorToBeDelete,
  setDeleteModalVisibility,
} = coVendorSlice.actions;

export const coVendorReducer = coVendorSlice.reducer;

const handleError = (e: any) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.failed));
  if (e?.data && e?.data.message) {
    notify(e?.data.message, 'error');
  } else {
    notify(null, 'error');
  }
};

export const getPermissionTable = () => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));

  getApiCall(
    `${endPoints.coVendor.permissionTable}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(updateBackendPermissionTable(data.data));
      }
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const addVendor = (payload: any, cb: (d: any) => void) => {
  const { getState } = store;
  const {
    coVendor: { permissionTable },
  }: RootState = getState();

  payload['permissions'] = getNonNullModules(permissionTable);

  if (payload['lastName'] === null) {
    payload['lastName'] = '';
  }
  postApiCall(
    endPoints.coVendor.vendorCRUD,
    payload,
    (s: AxiosResponse) => {
      const { data } = s;
      if (data) {
        cb(data);
      }
    },
    (e: any) => {
      cb(e);
      handleError(e);
    },
  );
};

export const updateVendor = (payload: any, cb: (d: any) => void) => {
  const { getState } = store;
  const {
    coVendor: { coVendorDetails, permissionTable },
  }: RootState = getState();
  if (payload['lastName'] === null) {
    payload['lastName'] = '';
  }

  payload['permissions'] = getNonNullModules(permissionTable);

  putApiCall(
    `${endPoints.coVendor.vendorCRUD}/${coVendorDetails._id}`,
    payload,
    (s: AxiosResponse) => {
      const { data } = s;
      if (data) {
        cb(data);
      }
    },
    (e: any) => {
      cb(e);
      handleError(e);
    },
  );
};

export const getAndSetVendorList = (
  query: string = '?pageNo=1&limit=10',
): any => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  const { searchKeywords, filter } = store.getState().coVendor;
  const searchQuery = searchKeywords
    ? `&searchKey=${encodeURIComponent(searchKeywords)}`
    : '';
  let filterQuery = '';

  if (filter) {
    if (filter.fromDate) {
      filterQuery += `&fromDate=${filter.fromDate}`;
    }
    if (filter.toDate) {
      filterQuery += `&toDate=${filter.toDate}`;
    }
    if (filter.status) {
      filterQuery += `&status=${filter.status.toUpperCase()}`;
    }
  }
  const params = `${query}${searchQuery}${filterQuery}`;
  const apiEndpoint = endPoints.coVendor.vendorCRUD;

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setVendorList(data.data));
      }
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const getAndSetVendorDetails = (id: string) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  getApiCall(
    `${endPoints.coVendor.vendorCRUD}/${id}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setVendorDetails(data.data));
      }
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const updateVendorStatus = (
  id: string,
  status: string,
  cb: () => void,
) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.loading));
  putApiCall(
    `${endPoints.coVendor.vendorCRUD}/${id}/status`,
    {
      status,
    },
    (d: any) => {
      if (d.data && d.data.statusCode === apiSuccessCode.accepted) {
        setTimeout(() => {
          cb();
          dispatch(updateActionApiStatus(ApiStatus.succeeded));
        }, 1000);
      } else {
        notify(t('msgs.err.statusUpdate'), 'error');
        dispatch(updateActionApiStatus(ApiStatus.failed));
      }
    },
    (e: any) => {
      dispatch(updateActionApiStatus(ApiStatus.failed));
      notify(t('msgs.err.statusUpdate'), 'error');
    },
  );
};

export const deleteVendor = (cb: (d: any) => void) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.loading));

  const { vendorToBeDelete } = store.getState().coVendor;
  deleteApiCall(
    `${endPoints.coVendor.vendorCRUD}/${vendorToBeDelete}`,
    {},
    (d: any) => {
      cb(d);
      dispatch(updateActionApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      cb(e);
      handleError(e);
      dispatch(updateActionApiStatus(ApiStatus.failed));
    },
  );
};
