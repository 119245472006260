import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

interface Props {
  label?: string;
  name: string;
  formProps?: any;
  minDate?: any;
  disabled?: boolean;
  error?: boolean;
  helperText?: any;
  requiredField?: boolean | any;
  maxDate?: any;
  disablePast?: boolean;
  disableFuture?: boolean;
  defaultValue?: any;
  onChange?: any;
  value?: any;
  showTime?: any;
  highLighted?: boolean;
}

const DateInput: React.FC<Props> = (props) => {
  const {
    label,
    name,
    formProps,
    minDate,
    disabled,
    error,
    helperText,
    requiredField,
    maxDate,
    disablePast,
    disableFuture,
    defaultValue,
    onChange,
    value,
    showTime,
    highLighted,
  } = props;

  return formProps && formProps.control ? (
    <Controller
      control={formProps.control}
      name={name}
      render={({ field }) => (
        <FormControl
          fullWidth
          className={`customSelect dateInput ${error ? 'border-red' : ''} ${
            highLighted ? 'highLighted' : ''
          }`}
          error={error ? true : false}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel className="formLabel">
              {label} {requiredField ? <sup>*</sup> : null}{' '}
            </FormLabel>
            <DatePicker
              className="dateInputField"
              {...field}
              disabled={disabled}
              value={field.value ? dayjs(field.value) : defaultValue ?dayjs(defaultValue) :null }
              views={['year', 'day']}
              showDaysOutsideCurrentMonth={false}
              minDate={minDate ? dayjs(minDate) : ''}
              defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
              maxDate={maxDate ? dayjs(maxDate) : ''}
              slots={{
                openPickerIcon: CalendarMonthOutlinedIcon,
              }}
              disablePast={disablePast}
              disableFuture={disableFuture}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{
                width: '100%',
                input: {
                  padding: '8.5px 14px',
                },
              }}
              onError={(error, value) => {
                if (error === 'minDate' || error === 'invalidDate') {
                  // Handle the case where the selected date is below the minimum date
                  field.onChange(minDate ? dayjs(minDate) : null);
                }
              }}
            />
          </LocalizationProvider>
          <FormHelperText className={error ? 'Mui-error' : ''}>
            {helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  ) : (
    <FormControl
      fullWidth
      className={`customSelect dateInput ${error ? 'border-red' : ''}`}
      error={error ? true : false}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormLabel className="formLabel">{label}</FormLabel>
        <DatePicker
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={onChange}
          views={['year', 'day']}
          showDaysOutsideCurrentMonth={false}
          minDate={minDate ? dayjs(minDate) : ''}
          maxDate={maxDate ? dayjs(maxDate) : ''}
          disablePast={disablePast}
          disableFuture={disableFuture}
          value={dayjs(value) || null}
          format="MM-DD-YYYY"
          slots={{
            openPickerIcon: CalendarMonthOutlinedIcon,
          }}
          slotProps={{
            textField: {
              size: 'small',
            },
          }}
          sx={{
            width: '100%',
            input: {
              padding: '8.5px 14px',
            },
          }}
        />
      </LocalizationProvider>
      <FormHelperText className={error ? 'Mui-error' : ''}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default DateInput;
