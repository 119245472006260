import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormHelperText, FormLabel, IconButton, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { KeyboardEvent } from 'react';
import { Controller } from 'react-hook-form';
import { CustomKeys } from '../types';
import { capitalizeFirstLetter, generateShortKey } from '../Utils/helperFns';
import CloseIcon from '@mui/icons-material/Close';
interface Props {
  id: string;
  labelText?: string;
  placeHolderText?: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  disabled?: boolean;
  value?: any;
  onChange?: any;
  options: any;
  control?: null | any;
  isError?: boolean;
  requiredField?: boolean | any;
  customKeys?: CustomKeys;
  itemDisableLogic?: (item: any) => boolean;
  customeKeyPlaceholder?: boolean;
  hintText?: string;
  setValue?: any;
  index?: any;
  isNone?: any;
  heading?: any;
}
export default function CustomSelect(props: Props) {
  const {
    id,
    labelText,
    placeHolderText,
    name = '',
    error,
    helperText,
    defaultValue,
    disabled,
    value,
    onChange,
    options,
    control,
    isError,
    requiredField,
    customKeys,
    itemDisableLogic,
    customeKeyPlaceholder,
    hintText,
    setValue,
    index, 
    isNone,
    heading
  } = props;

  const [currentValue, setcurrentValue] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setcurrentValue(event.target.value as string);
  };

  return (
    <FormControl
      fullWidth
      className="customSelect"
      error={error ? true : false}
    >
      <FormLabel className="formLabel">
        {labelText} {requiredField ? <sup>*</sup> : null}{' '}
      </FormLabel>
      {control ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            const handleKeyDown = (
              event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              if (event.key === 'Enter') {
                const selectedOption = options.find(
                  (option: any) =>
                    option[customKeys ? customKeys.value : ''] ===
                    (event.target as HTMLDivElement).textContent,
                );
                if (selectedOption) {
                  field.onChange(event.target.value);
                }
              }
            };

            return (
              field && (
                <>
                  <Select
                    labelId={id}
                    size="small"
                    id="select"
                    placeholder={placeHolderText}
                    {...field}
                    displayEmpty
                    disabled={disabled}
                    value={value || value === 0 ? value : field.value}
                    onChange={onChange ? (e) => onChange(e, field, name, index)

                      : field.onChange}
                    defaultValue={defaultValue ? defaultValue : undefined}
                    IconComponent={KeyboardArrowDownIcon}
                    onKeyDown={handleKeyDown}
                    renderValue={
                      customeKeyPlaceholder && !field.value
                        ? (selected) => {
                          if (selected === undefined || selected === null || selected === '') {
                            return (
                              <div className="select_placeholder">
                                {placeHolderText}
                              </div>
                            );
                          }
                          return capitalizeFirstLetter(selected);
                        }
                        : customKeys
                          ? undefined
                          : (selected) => {
                            if (selected === undefined || selected === null || selected === '') {
                              return (
                                <div
                                  key={generateShortKey(5)}
                                  className="select_placeholder"
                                >
                                  {placeHolderText}
                                </div>
                              );
                            }
                            return selected;
                          }
                    }
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <div className="inputEndIcon">
                        <IconButton
                          color="primary"
                          sx={{ p: '10px' }}
                          aria-label="directions"
                          onClick={() => {}}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                        </div>
                        </InputAdornment>
                      )
                    }}
                  >
                    {(customeKeyPlaceholder && field.value) || isNone && (
                      <MenuItem value="">
                       {heading ? heading  : "None"}{' '}
                      </MenuItem>
                    )}
                    {customKeys
                      ? options.map((item: any) => {
                        return (
                          <MenuItem
                            key={item[customKeys.value]}
                            value={item[customKeys.value]}
                          >
                            {item[customKeys.label]}
                          </MenuItem>
                        );
                      })
                      : options.map((item: any) => {
                        return (
                          <MenuItem
                            value={item}
                            key={generateShortKey(4)}
                            disabled={
                              itemDisableLogic
                                ? itemDisableLogic(item)
                                : undefined
                            }
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </>
              )
            );
          }}
        />
      ) : (
        <>
          <Select
            labelId={id}
            id="select"
            size="small"
            value={value ? value : currentValue}
            defaultValue={defaultValue}
            onChange={onChange ? onChange : handleChange}
            placeholder={placeHolderText}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
            disabled={disabled}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <div className="select_placeholder">{placeHolderText}</div>
                );
              }
              return selected;
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <div className="inputEndIcon">
                <IconButton
                  color="primary"
                  sx={{ p: '10px' }}
                  aria-label="directions"
                  onClick={() => {}}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                </div>
                </InputAdornment>
              )
            }}
          >
            {/* <MenuItem value='' disabled>
            {placeHolderText}
          </MenuItem> */}
            {customKeys
              ? options.map((item: any) => {
                return (
                  <MenuItem
                    key={item[customKeys.value]}
                    value={item[customKeys.value]}
                  >
                    {item[customKeys.label]}
                  </MenuItem>
                );
              })
              : options.map((item: any) => {
                return (
                  <MenuItem
                    value={item}
                    disabled={
                      itemDisableLogic ? itemDisableLogic(item) : undefined
                    }
                  >
                    {item}
                  </MenuItem>
                );
              })}
          </Select>
        </>
      )}

      <FormHelperText className={error ? 'Mui-error' : ''}>
        {helperText}
      </FormHelperText>
      <FormHelperText className="selectHintText">{hintText}</FormHelperText>
    </FormControl>
  );
}
