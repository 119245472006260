import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import {
  apiErrorCode,
  apiSuccessCode,
  deleteApiCall,
  getApiCall,
  patchApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import {
  ApiStatus,
  Department,
  ProductState,
  RiskManagementState,
} from '../types';
import { setCommonDataLoader, updateActionApiStatus } from './common.slice';
import store, { useAppSelector } from './store';
import { logout } from './auth.slice';
import React from 'react';
import dayjs from 'dayjs';

const initialState: RiskManagementState = {
  riskList: [],
  ordersList: {},
  accountList: {},
  usersList: {},
  loading: false,
  error: false,
  filter: null,
  searchKeywords: '',
  userData: null,
  currentTab: 0,
  entitylist: [],
  countrylist: {},
  citylist: {},
  departmentList:{},
  studioList:{},
  productionList:{},
  companyList:{},
  universityList:{},
  pageNo:1,
};

const riskMngslice = createSlice({
  name: 'riskmng',
  initialState,
  reducers: {
    setRiskmngList: (state, action) => {
      const { payload }: any = action;
      state.riskList = payload;
    },
    setPageNo: (state, action) => {
      const { payload }: any = action;
      state.pageNo = payload;
    },
    setUsersList: (state, action) => {
      const { payload }: any = action;
      state.usersList = payload;
    },
    setAccountsList: (state, action) => {
      const { payload }: any = action;
      state.accountList = payload;
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action;
      state.searchKeywords = payload;
    },
    setFilters: (state, action) => {
      const { payload }: any = action;

      state.filter = payload;
    },
    setUserDetails: (state, action) => {
      const { payload }: any = action;
      state.userData = payload;
    },
    setCurrentTab: (state, action) => {
      // Action to set the current tab
      state.currentTab = action.payload;
    },
    setEntityListing: (state, action) => {
      const { payload }: any = action;
      state.entitylist = payload;
    },
    setCountryCityList: (state, action) => {
      const { payload }: any = action;
      state.countrylist = payload;
    },
    setCountryList: (state, action) => {
      const { payload }: any = action;
      state.citylist = payload;
    },
    setDepartmentList: (state, action) => {
      const { payload }: any = action;
      state.departmentList = payload;
    },
    setStudioList: (state, action) => {
      const { payload }: any = action;
      state.studioList = payload;
    },
    setProductionList: (state, action) => {
      const { payload }: any = action;
      state.productionList = payload;
    },
   setCompanyList: (state, action) => {
      const { payload }: any = action;
      state.companyList = payload;
    },
    setUniversityList: (state, action) => {
      const { payload }: any = action;
      state.universityList = payload;
    },
  },
});

export const {
  setRiskmngList,
  setFilters,
  setSearchKeywords,
  setUserDetails,
  setDepartmentList,
  setCurrentTab,
  setUsersList,
  setAccountsList,
  setEntityListing,
  setCountryCityList,
  setCountryList,
  setStudioList,
  setProductionList,
  setCompanyList,
  setUniversityList,
  setPageNo
} = riskMngslice.actions;

const handleError = (e: any) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.failed));

  if (e.data && e.data.message) {
    notify(e.data.message, 'error');
  }
  if (e.data && e.data.statusCode === apiErrorCode.unauthorized) {
    logout();
  }
};

export const getRiskManagementList = (
  query: string = '?pageNo=1&limit=10',
  type: string = '',
): any => {
  const { getState } = store;
  const searchKeyword = getState().riskMngslice.searchKeywords;
  const searchQuery = searchKeyword
    ? `&searchKey=${encodeURIComponent(searchKeyword)}`
    : '';
  const filters = getState().riskMngslice.filter;

  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate() + 2);
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.status) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&status=${filters.status?.toUpperCase()}`;
      // })
    }
    if (filters.availableStatus) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&availableStatus=${filters.availableStatus
        ?.toUpperCase()
        .replace(/\s+/g, '_')}`;
      // })
    }
  }
  let listType = '';
  if (Boolean(type)) {
    listType += `&tabName=${type}`;
  }
  const params = `${query}${listType}${searchQuery}${filterQuery}`;
  const apiEndpoint = endPoints.riskmanagement.risklist;
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        if (type === 'USERS') {
          dispatch(setUsersList(data.data));
          dispatch(setPageNo(data.data.pageNo));
        } else {
          dispatch(setAccountsList(data.data));
        }
        dispatch(setRiskmngList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const addRiskManager = (data: any, cb: () => void) => {
  const { dispatch, getState } = store;

  postApiCall(
    endPoints.riskmanagement.addriskManager,
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;

      if (statusCode && statusCode === apiSuccessCode.created) {
        notify(s.data.message, 'success');
        cb();
      } else if (statusCode && statusCode === apiErrorCode.validationError) {
        notify(s.data.message, 'error');
      }

      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getUserDetails = (id: string): any => {
  const { dispatch } = store;
  const apiEndpoint = endPoints.riskmanagement.userDetails;
  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}/${id}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCommonDataLoader(false));
        dispatch(setUserDetails(data.data));
      }
    },
    (e: any) => {
      dispatch(setCommonDataLoader(false));
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching branches list`, 'error');
      }
    },
  );
};


export const getDepartmentList = (): any => {
  const { dispatch } = store;
  const apiEndpoint = endPoints.riskmanagement.departmentList;
  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCommonDataLoader(false));
        dispatch(setDepartmentList(data.data));
      }
    },
    (e: any) => {
      dispatch(setCommonDataLoader(false));
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        // notify(`Error while fetching branches list`, 'error');
      }
    }
  );
};


export const updateRiskManager = (data: any, id: any, cb: any) => {
  const { dispatch, getState } = store;
  const apiendpoint = endPoints.riskmanagement.updateRiskManager;
  const params = id;
  putApiCall(
    `${apiendpoint}${params}`,
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.created) {
        notify(s.data.message, 'success');
        cb();
      } else if (statusCode && statusCode === apiErrorCode.validationError) {
        notify(s.data.message, 'error');
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const deleteExperience = (payload: any,id?:any) => {
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));
  deleteApiCall(
    `${endPoints.riskmanagement.deleteExperience}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      dispatch(setCommonDataLoader(false));
      if(id) {
        getUserDetails(id)
      }
 
    },
    (e: any) => {
      // handleError(e)
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const blockUnblockUser = (data: any, id: any, callback: any,pageNo?: number) => {
  const { dispatch } = store;

  dispatch(setCommonDataLoader(true));

  const { status } = data;

  patchApiCall(
    `${endPoints.riskmanagement.blockUnblock}${id}`,
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success) {
        notify(message, 'success');
        getRiskManagementList(`?pageNo=${pageNo || 1}&limit=10`, 'USERS');
        callback();
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      // cb(false)
      dispatch(setCommonDataLoader(false));
    },
  );
};
export const getEntityListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.entityListing;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setEntityListing(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getCountryCityListing = (searchKey?: string) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint =
    endPoints.riskmanagement.countryCityListing +
    (searchKey ? '?limit=50&searchKey=' + searchKey : '');
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCountryCityList(data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getCountryListing = (id: any) => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.countryList + '?cityId=' + id;
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCountryList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getStudioListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.studioList
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setStudioList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getProductionListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.productionList
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setProductionList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getCompanyListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.companyList
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setCompanyList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export const getUniversityListing = () => {
  const { dispatch } = store;
  dispatch(setCommonDataLoader(true));
  const apiendpoint = endPoints.riskmanagement.university
  getApiCall(
    `${apiendpoint}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setUniversityList(data.data));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setCommonDataLoader(false));
    },
  );
};

export default riskMngslice.reducer;
