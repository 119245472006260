import { createSlice } from '@reduxjs/toolkit';
import store from './store';
import endPoints from '../api/endpoint';
import { getApiCall } from '../api/methods';
import { notify } from '../Utils/toastify';
import { StaticContentState } from '../types';
import { STATIC_CONTENT } from '../Utils/constantData';

const initialState: StaticContentState = {
  data: null,
  loading: false,
};
const staticContentSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setData: (state, action) => {
      const { payload }: any = action;
      state.data = payload;
      return state;
    },
    setDataLoader: (state, action) => {
      const { payload }: any = action;
      state.loading = payload;
    },
  },
});

export const { setData, setDataLoader } = staticContentSlice.actions;

export default staticContentSlice.reducer;

export const getAndSetStaticData = async (type: STATIC_CONTENT) => {
  store.dispatch(setDataLoader(true));
  getApiCall(
    `${endPoints.staticData.content}${STATIC_CONTENT[type]}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        store.dispatch(setData(data.data));
      }
      store.dispatch(setDataLoader(false));
    },
    (e: any) => {
      console.log(e);
      store.dispatch(setDataLoader(false));
      notify(`Error while fetching ${type} data`, 'error');
    },
    { Authorization: `Basic ${btoa('reel:reel@123')}` },
  );
};
