import { createSlice } from '@reduxjs/toolkit';
import {
  BACKEND_P_TYPES,
  DELIVERY_TYPES,
  DISCOUNT_TYPES,
  ITEM_TYPE,
  PACKAGE_TYPES,
  PRICE_MODE_TYPES,
  PRODUCT_TABS,
  PRODUCT_TYPES,
  PRODUCT_TYPE_BOTH,
} from '../Utils/constantData';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import {
  apiSuccessCode,
  deleteApiCall,
  getApiCall,
  patchApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import { ApiStatus, Department, ProductState } from '../types';
import { logout } from './auth.slice';
import store, { RootState } from './store';
import dayjs from 'dayjs';

const initialState: ProductState = {
  products: [],
  loading: false,
  error: false,
  page: 1,
  selectedIds: [],
  searchKeywords: '',
  filter: null,
  actionApiStatus: ApiStatus.idle,
  detailsApiStatus: ApiStatus.idle,
  productDetails: null,
  sequenceList: [],
  selectedProductsDetails: [],
  addProductVariantPayload: [],
  variantStatus: {
    submit: false,
    name: '',
  },
  combinationVariants: [],
  variantScroll: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductList: (state, action) => {
      const { payload }: any = action;
      state.products = payload;
    },
    setProductLoader: (state, action) => {
      const { payload }: any = action;
      state.loading = payload;
    },
    setProductError: (state, action) => {
      const { payload }: any = action;
      state.error = payload;
    },
    setPage: (state, action) => {
      const { payload }: any = action;
      state.error = payload;
    },
    setSelected: (state, action) => {
      const { payload }: any = action;
      state.selectedIds = payload;
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action;
      state.searchKeywords = payload;
    },
    setFilters: (state, action) => {
      const { payload }: any = action;
      state.filter = payload;
    },
    updateActionApiStatus: (state, action) => {
      const { payload }: any = action;
      state.actionApiStatus = payload;
    },
    updateDetailsApiStatus: (state, action) => {
      const { payload }: any = action;
      state.detailsApiStatus = payload;
    },
    setProductDetails: (state, action) => {
      const { payload } = action;
      state.productDetails = payload;
    },
    setSequenceList: (state, action) => {
      const { payload } = action;
      state.sequenceList = payload;
    },
    setSelectedProductList: (state, action) => {
      const { payload } = action;
      state.selectedProductsDetails = payload;
    },
    setAddProductVariantPayload: (state, action) => {
      const { payload } = action;
      state.addProductVariantPayload = payload;
    },
    setAddProductVariantStatus: (state, action) => {
      const { payload } = action;
      state.variantStatus = payload;
    },
    setCombinationVariants: (state, action) => {
      const { payload } = action;
      state.combinationVariants = payload;
    },
    setVariantScroll: (state, action) => {
      const { payload } = action;
      state.variantScroll = payload;
    },
  },
});

export const {
  setProductList,
  setProductLoader,
  setProductError,
  setPage,
  setSelected,
  setSearchKeywords,
  setFilters,
  updateActionApiStatus,
  updateDetailsApiStatus,
  setProductDetails,
  setSequenceList,
  setSelectedProductList,
  setAddProductVariantPayload,
  setAddProductVariantStatus,
  setCombinationVariants,
  setVariantScroll,
} = productSlice.actions;

export default productSlice.reducer;

const handleError = (e: any) => {
  const { dispatch } = store;
  setTimeout(() => {
    dispatch(updateActionApiStatus(ApiStatus.failed));
  }, 1500);

  if (e.data && e.data.message) {
    notify(e.data.message, 'error');
  }
  if (e.data && e.data.statusCode === 401) {
    logout();
  }
};

export const getAndSetProductList = (
  type: string = '',
  query: string = '?pageNo=1&limit=10&sortBy=sequenceNo&sortOrder=1',
  branchId: string | null = null,
  notQuery: string = '',
  isActiveOnlyFilter: boolean = false,
): any => {
  let apiEndpoint = endPoints.product.list;

  const { getState, dispatch } = store;
  const {
    product: { searchKeywords },
  }: RootState = getState();
  const searchQuery = searchKeywords
    ? `&searchKey=${encodeURIComponent(searchKeywords)}`
    : '';
  const filters = store.getState().product.filter;
  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate() + 2);
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.department && type !== PRODUCT_TABS.package.value) {
      filterQuery += `&department=${filters.department._id}`;
    } else if (filters.department && type === PRODUCT_TABS.package.value) {
      filterQuery += `&departments=${filters.department._id}`;
    }
    if (filters.type) {
      const pType =
        filters.type === PRODUCT_TYPES.rentAndPurchase
          ? BACKEND_P_TYPES.RENTAL_AND_PURCHASE
          : filters.type === PRODUCT_TYPE_BOTH.allProducts
          ? PRODUCT_TYPE_BOTH.allProducts
          : filters.type.toUpperCase();
      filterQuery += `&type=${pType}`;
    }
    if (filters.isFeaturedProducts) {
      filterQuery += `&isFeaturedProducts=${filters.isFeaturedProducts}`;
    }
  }
  if (branchId) {
    filterQuery += `&branchId=${branchId}`;
  }
  let listType = '';
  if (Boolean(type)) {
    listType += `&listType=${type}`;
  }
  if (type === PRODUCT_TABS.package.value) {
    apiEndpoint = endPoints.product.package.list;
    listType = ''; ///Reseting listType to blank in case of packgage
  }
  if (Boolean(notQuery)) {
    listType += `&not=${
      notQuery === PRODUCT_TYPES.rentAndPurchase
        ? BACKEND_P_TYPES.RENTAL_AND_PURCHASE
        : notQuery.toUpperCase()
    }`;
  }
  if (isActiveOnlyFilter) {
    listType += `&isActiveOnly=${isActiveOnlyFilter}`;
  }

  const params = `${query}${listType}${searchQuery}${filterQuery}`;

  dispatch(setProductLoader(true));

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setProductList(data.data));
      }
      dispatch(setProductLoader(false));
    },
    (e: any) => {
      dispatch(setProductList([]));
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching ${type} list`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(setProductLoader(false));
    },
    branchId ? { ['branch_id']: branchId } : {},
  );
};

export const getProductsAndReturn = (
  query: string = '?pageNo=1&limit=5',
  callBack: (data: any) => void,
) => {
  const { dispatch } = store;
  dispatch(setProductLoader(true));
  getApiCall(
    `${endPoints.product.list}${query}`,
    (d: any) => {
      const { data } = d;
      callBack(data);
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(setProductLoader(false));
    },
  );
};

export const toggleProductApiStatus = (
  id: string,
  status: string,
  cb: () => void,
  type: string = PRODUCT_TABS.product.value,
) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.loading));
  if (type === PRODUCT_TABS.package.value) {
    patchApiCall(
      `${endPoints.product.package.statusUpdate}${id}/status`,
      {
        status,
      },
      (d: any) => {
        if (
          d.data &&
          (d.data.statusCode === apiSuccessCode.success ||
            d.data.statusCode === apiSuccessCode.accepted)
        ) {
          setTimeout(() => {
            cb();
            dispatch(updateActionApiStatus(ApiStatus.idle));
          }, 1000);
        }
      },
      (e: any) => {
        dispatch(updateActionApiStatus(ApiStatus.failed));
        notify(`Error while updating status`, 'error');
      },
    );
  } else {
    putApiCall(
      `${endPoints.product.statusUpdate}${id}/status`,
      {
        status,
      },
      (d: any) => {
        if (
          d.data &&
          (d.data.statusCode === apiSuccessCode.success ||
            d.data.statusCode === apiSuccessCode.accepted)
        ) {
          setTimeout(() => {
            cb();
            dispatch(updateActionApiStatus(ApiStatus.succeeded));
          }, 1000);
        }
      },
      (e: any) => {
        dispatch(updateActionApiStatus(ApiStatus.failed));
        notify(`Error while updating status`, 'error');
      },
    );
  }
};

export const getTemplateList = (
  query: string = '?pageNo=1&limit=5',
  callBack: (data: any) => void,
) => {
  const { dispatch } = store;

  getApiCall(
    `${endPoints.product.templateList}${query}`,
    (d: any) => {
      const { data } = d;
      callBack(data);
    },
    (e: any) => {
      handleError(e);
      callBack(null);
    },
  );
};

export const getSequenceList = (type: string) => {
  const { dispatch } = store;
  dispatch(setProductLoader(true));

  getApiCall(
    `${
      type === PRODUCT_TABS.package.value
        ? endPoints.product.package.sequenceList
        : endPoints.product.sequenceList
    }?type=${type}`,
    (d: any) => {
      const { data } = d;
      dispatch(setSequenceList(data.data));
      dispatch(setProductLoader(false));
    },
    (e: any) => {
      handleError(e);
      dispatch(setProductLoader(false));
    },
  );
};

export const addVariantProductStatus = (data: any) => {
  // console.log('DDD =>', data);
  const { dispatch } = store;
  dispatch(setAddProductVariantStatus(data));
};

export const addVariantProductPayload = (data: any) => {
  // console.log('DDD =>', data);
  const { dispatch } = store;
  let { addProductVariantPayload } = store.getState()?.product;

  dispatch(setAddProductVariantPayload([...addProductVariantPayload, data]));
};

export const addOrUpdateProduct = (data: any, type: string, cb: () => void) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.loading));

  data['type'] =
    data['type'] === PRODUCT_TYPES.rentAndPurchase
      ? BACKEND_P_TYPES.RENTAL_AND_PURCHASE
      : data['type'].toUpperCase();

  // data['department'] = data['department'].map((d: Department) => d._id);
  data['department'] = [data['department']._id];
  // data['category'] = data['category'].map((d: any) => d._id);
  data['category'] = [data['category']._id];

  if (data['discount']) {
    data['discountType'] = DISCOUNT_TYPES[data['discountType']];
  } else {
    delete data['discountType'];
  }

  if (
    data['deliveryType'] &&
    (data['type'] === BACKEND_P_TYPES.PURCHASE ||
      data['type'] === BACKEND_P_TYPES.RENTAL_AND_PURCHASE)
  ) {
    data['deliveryType'] = DELIVERY_TYPES[data['deliveryType']];
  } else {
    delete data['deliveryType'];
  }

  if (data['rentDeliveryType']) {
    data['rentDeliveryType'] = DELIVERY_TYPES[data['rentDeliveryType']];
  }

  if (!data['deliveryNote']) {
    delete data['deliveryNote'];
  }

  if (!data['description'] || !data['description'].trimStart()) {
    delete data['description'];
  }

  if (data['rentDiscount'] === null) {
    delete data['rentDiscountType'];
  }

  if (data['rentDiscountType'] && data['rentDiscount']) {
    data['rentDiscountType'] = DISCOUNT_TYPES[data['rentDiscountType']];
  } else {
    delete data['rentDiscountType'];
  }

  Object.keys(data).forEach((k: string) => {
    if (data['type'] === BACKEND_P_TYPES.PURCHASE && k.includes('rent')) {
      delete data[k];
    }
    if (data[k] === null || data[k] == '') {
      delete data[k];
    }
  });

  if (
    data['securityDepositExist'] === false ||
    data['securityDepositExist'] === undefined
  ) {
    delete data['depositPercentage'];
  }

  if (data['type'] === BACKEND_P_TYPES.PURCHASE) {
    delete data['securityDepositExist'];
    delete data['depositPercentage'];
    delete data['rentPricing'];
  } else {
    data['rentPricing'] = data['rentPricing'].map((slab: any) => {
      slab['minRateModel'] = slab['minRateModel'];
      if (slab['OTStartTime'] === null || slab['OTStartTime'] == '') {
        delete slab['OTStartTime'];
      }
      if (slab['DTStartTime'] === null || slab['DTStartTime'] == '') {
        delete slab['DTStartTime'];
      }
      if (slab['shiftLength'] === null) {
        delete slab['shiftLength'];
      }

      return slab;
    });
  }

  if (
    data['isFeatureProduct'] === false ||
    data['isFeatureProduct'] === undefined
  ) {
    delete data['sequenceNo'];
  }
  data['itemType'] = ITEM_TYPE.PRODUCT;
  if (type === 'EditProduct') {
    const productId = data['_id'];
    delete data['_id'];
    putApiCall(
      `${endPoints.product.update}${productId}`,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.accepted)
        ) {
          cb();
        }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  } else {
    postApiCall(
      endPoints.product.create,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.accepted)
        ) {
          cb();
        }

        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  }
};

export const addProductVariant = (
  data: any,
  cb?: any,
  type?: string,
  errorCB?: any,
) => {
  const { dispatch } = store;
  // console.log('Payyyy to api =>', data);
  if (type === 'edit') {
    const id = data['id'];
    delete data['id'];

    putApiCall(
      `${endPoints.product.productVariant.updateVariant(id)}`,
      data,
      (s: any) => {
        // console.log('RESSSSS=>', s);
        const {
          data: { statusCode },
        } = s;
        // console.log('IN RESSSSSSSSSSS=>>>>', statusCode);
        if (statusCode) {
          // console.log('In Status code');
          cb();
        }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
        errorCB();
      },
    );
  } else {
    postApiCall(
      `${endPoints.product.productVariant.createVariant}`,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.accepted)
        ) {
          // cb();
        }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
        cb();
      },
      (e: any) => {
        handleError(e);
        errorCB();
      },
    );
  }
};

export const AddCombinationVariants = (data: any) => {
  const { dispatch } = store;
  dispatch(setCombinationVariants(data));
};

export const removeCombinationVariants = () => {
  const { dispatch } = store;
  dispatch(setCombinationVariants([]));
};

export const addOrUpdatePackage = (data: any, type: string, cb: () => void) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.loading));

  if (data['type']) {
    data['type'] =
      data['type'] === PRODUCT_TYPES.rentAndPurchase
        ? BACKEND_P_TYPES.RENTAL_AND_PURCHASE
        : data['type'].toUpperCase();
  }

  if (data['packageType'] === PACKAGE_TYPES[1].value) {
    data['type'] = BACKEND_P_TYPES.SERVIC;
  }

  // data['department'] = data['department'].map((d: Department) => d._id);
  data['departments'] = [data['department']._id];
  // data['category'] = data['category'].map((d: any) => d._id);
  data['categories'] = [data['category']._id];

  data['products'] = data['products'].map((d: any) => {
    return { _id: d.product._id, title: d.product.title, qty: d.qty };
  });
  data['services'] = data['services'].map((d: any) => {
    return { _id: d.product._id, title: d.product.title, qty: d.qty };
  });

  delete data['department'];
  delete data['category'];

  if (data['discount']) {
    data['discountType'] = DISCOUNT_TYPES[data['discountType']];
  } else {
    delete data['discountType'];
  }

  if (
    data['deliveryType'] &&
    (data['type'] === BACKEND_P_TYPES.PURCHASE ||
      data['type'] === BACKEND_P_TYPES.RENTAL_AND_PURCHASE)
  ) {
    data['deliveryType'] = DELIVERY_TYPES[data['deliveryType']];
  } else {
    delete data['deliveryType'];
  }

  if (data['rentDeliveryType']) {
    data['deliveryType'] = DELIVERY_TYPES[data['rentDeliveryType']];
    delete data['rentDeliveryType'];
  }

  if (data['rentDeliveryFee']) {
    data['deliveryFee'] = data['rentDeliveryFee'];
    delete data['rentDeliveryFee'];
  }

  if (data['rentPerDiemCost'] !== null) {
    data['perDiemCost'] = data['rentPerDiemCost'];
    delete data['rentPerDiemCost'];
  }

  if (data['rentTravelCost'] !== null) {
    data['travelCost'] = data['rentTravelCost'];
    delete data['rentTravelCost'];
  }

  if (data['rentDiscountType'] && data['rentDiscount']) {
    data['discountType'] = DISCOUNT_TYPES[data['rentDiscountType']];
    delete data['rentDiscountType'];
  }
  if (data['rentDiscount']) {
    data['discount'] = data['rentDiscount'];
    delete data['rentDiscount'];
  }

  if (data['rentDiscount'] === null) {
    delete data['rentDiscountType'];
  }

  if (data['rentDiscountType'] && data['rentDiscount']) {
    data['rentDiscountType'] = DISCOUNT_TYPES[data['rentDiscountType']];
  } else {
    delete data['rentDiscountType'];
  }

  if (!data['deliveryNote']) {
    delete data['deliveryNote'];
  }

  if (!data['description'] || !data['description'].trimStart()) {
    delete data['description'];
  }

  Object.keys(data).forEach((k: string) => {
    if (data['type'] === BACKEND_P_TYPES.PURCHASE && k.includes('rent')) {
      delete data[k];
    }
    if (data[k] === null || data[k] == '') {
      delete data[k];
    }
  });

  if (
    data['securityDepositExist'] === false ||
    data['securityDepositExist'] === undefined
  ) {
    delete data['depositPercentage'];
  }

  if (data['type'] === BACKEND_P_TYPES.PURCHASE) {
    delete data['securityDepositExist'];
    delete data['depositPercentage'];
    delete data['rentPricing'];
  } else {
    data['rentPricing'] = data['rentPricing'].map((slab: any) => {
      slab['minRateModel'] = slab['minRateModel'];
      if (slab['OTStartTime'] === null || slab['OTStartTime'] == '') {
        delete slab['OTStartTime'];
      }
      if (slab['DTStartTime'] === null || slab['DTStartTime'] == '') {
        delete slab['DTStartTime'];
      }
      if (slab['shiftLength'] === null) {
        delete slab['shiftLength'];
      }
      if (data['packageType'] === PACKAGE_TYPES[1].value) {
        slab['priceMode'] = PRICE_MODE_TYPES.PER_HOUR;
      } else {
        delete slab['minShiftLength'];
        delete slab['priceMode'];
        delete slab['shiftLength'];
      }
      return slab;
    });
  }

  if (
    data['isFeatureProduct'] === false ||
    data['isFeatureProduct'] === undefined
  ) {
    delete data['sequenceNo'];
  }
  if (data['tags']) {
    data['tags'] = data['tags'].map((t: any) => t.tag).join(',');
  }
  if (data['cardTags']) {
    data['cardTags'] = data['cardTags'].map((t: any) => t.tag).join(',');
  }
  data['itemType'] = ITEM_TYPE.PACKAGE;

  if (type === 'EditPackage') {
    const productId = data['_id'];
    delete data['_id'];
    patchApiCall(
      `${endPoints.product.package.update}${productId}`,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.accepted ||
            statusCode === apiSuccessCode.success)
        ) {
          cb();
        }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  } else {
    postApiCall(
      endPoints.product.package.create,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.accepted)
        ) {
          cb();
        }

        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  }
};

export const addOrUpdateService = (data: any, type: string, cb: () => void) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.loading));
  // data['department'] = data['department'].map((d: Department) => d._id);
  data['department'] = [data['department']._id];
  // data['category'] = data['category'].map((d: any) => d._id);
  data['category'] = [data['category']._id];

  if (data['rentDeliveryType']) {
    data['rentDeliveryType'] = DELIVERY_TYPES[data['rentDeliveryType']];
  }

  if (!data['description'] || !data['description'].trimStart()) {
    delete data['description'];
  }

  if (data['rentDiscount'] === null) {
    delete data['rentDiscountType'];
  }

  if (data['rentDiscount'] === null) {
    delete data['rentDiscountType'];
  }

  if (data['rentDiscountType'] && data['rentDiscount']) {
    data['rentDiscountType'] = DISCOUNT_TYPES[data['rentDiscountType']];
  } else {
    delete data['rentDiscountType'];
  }

  data['rentPricing'] = data['rentPricing'].map((slab: any) => {
    if (
      slab['OTStartTime'] === null ||
      slab['OTStartTime'] == '' ||
      slab['priceMode'] === PRICE_MODE_TYPES.PER_DAY_WEEK_MONTH
    ) {
      delete slab['OTStartTime'];
    }
    if (
      slab['DTStartTime'] === null ||
      slab['DTStartTime'] == '' ||
      slab['priceMode'] === PRICE_MODE_TYPES.PER_DAY_WEEK_MONTH
    ) {
      delete slab['DTStartTime'];
    }
    if (slab['priceMode'] === PRICE_MODE_TYPES.PER_DAY_WEEK_MONTH) {
      delete slab['shiftLength'];
      delete slab['minShiftLength'];
    }
    if (slab['priceMode'] === PRICE_MODE_TYPES.PER_HOUR) {
      delete slab['extendedPriceMode'];
    }
    return slab;
  });

  data['tags'] = data['tags'].map((t: any) => t.tag).join(',');
  data['cardTags'] = data['cardTags'].map((t: any) => t.tag).join(',');

  Object.keys(data).forEach((k: string) => {
    if (data[k] === null || data[k] == '') {
      delete data[k];
    }
  });

  data['rentDeliveryType'] = 'DROP_OFFS';
  data['price'] = '';
  if (
    data['isFeatureProduct'] === false ||
    data['isFeatureProduct'] === undefined
  ) {
    delete data['sequenceNo'];
  }

  if (
    data['securityDepositExist'] === false ||
    data['securityDepositExist'] === undefined
  ) {
    delete data['depositPercentage'];
    data['securityDepositExist'] = false;
  }

  if (type === 'EditService') {
    data['deliveryType'] = 'DROP_OFFS';
    data['deliveryFee'] = '10';
  }
  data['itemType'] = ITEM_TYPE.SERVICE;

  if (type === 'EditService') {
    const productId = data['_id'];
    delete data['_id'];
    putApiCall(
      `${endPoints.product.update}${productId}`,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.accepted)
        ) {
          cb();
        }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  } else {
    postApiCall(
      endPoints.product.create,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.accepted)
        ) {
          cb();
        }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  }
};

export const getAndSetProductDetails = (
  id: string,
  type: string = 'product',
  cb?: any,
) => {
  const { dispatch } = store;
  dispatch(updateDetailsApiStatus(ApiStatus.loading));
  getApiCall(
    `${
      type === 'product'
        ? endPoints.product.edit
        : endPoints.product.package.edit
    }${id}`,
    (d: any) => {
      const { data } = d;
      if (data && data.data) {
        const pData = data.data;
        if (pData) {
          dispatch(setProductDetails(pData));
          cb && cb(pData);
        }
      } else {
        dispatch(setProductDetails(null));
      }
      dispatch(updateDetailsApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(setProductDetails(null));

      dispatch(updateDetailsApiStatus(ApiStatus.failed));
    },
  );
};

export const getAndSetProductVariantDetails = (id: string, cb?: any) => {
  const { dispatch } = store;
  dispatch(updateDetailsApiStatus(ApiStatus.loading));
  getApiCall(
    `${endPoints.product.edit}${id}`,
    (d: any) => {
      const { data } = d;
      if (data && data.data) {
        const pData = data.data;
        if (pData) {
          // dispatch(setProductDetails(pData));
          cb(pData);
        }
      } else {
        // dispatch(setProductDetails(null));
      }
      dispatch(updateDetailsApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      // dispatch(setProductDetails(null));

      dispatch(updateDetailsApiStatus(ApiStatus.failed));
    },
  );
};

export const AddAndUpdateServiceVariant = (
  data: any,
  cb: () => void,
  type?: string,
) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(ApiStatus.loading));

  if (data['rentDeliveryType']) {
    data['rentDeliveryType'] = DELIVERY_TYPES[data['rentDeliveryType']];
  }

  if (!data['description'] || !data['description'].trimStart()) {
    delete data['description'];
  }

  if (data['rentDiscount'] === null) {
    delete data['rentDiscountType'];
  }

  if (data['rentDiscountType'] && data['rentDiscount']) {
    data['rentDiscountType'] = DISCOUNT_TYPES[data['rentDiscountType']];
  } else {
    delete data['rentDiscountType'];
  }

  data['rentPricing'] = data['rentPricing'].map((slab: any) => {
    slab['minRateModel'] = slab['minRateModel'];
    if (slab['OTStartTime'] === null || slab['OTStartTime'] == '') {
      delete slab['OTStartTime'];
    }
    if (slab['DTStartTime'] === null || slab['DTStartTime'] == '') {
      delete slab['DTStartTime'];
    }
    return slab;
  });

  data['tags'] = data['tags'].map((t: any) => t.tag).join(',');
  data['cardTags'] = data['cardTags'].map((t: any) => t.tag).join(',');

  Object.keys(data).forEach((k: string) => {
    if (data[k] === null || data[k] == '') {
      delete data[k];
    }
  });

  data['rentDeliveryType'] = 'DROP_OFFS';
  data['price'] = '';
  if (
    data['isFeatureProduct'] === false ||
    data['isFeatureProduct'] === undefined
  ) {
    delete data['sequenceNo'];
  }

  if (
    data['securityDepositExist'] === false ||
    data['securityDepositExist'] === undefined
  ) {
    delete data['depositPercentage'];
    data['securityDepositExist'] = false;
  }

  if (type === 'EditService') {
    data['deliveryType'] = 'DROP_OFFS';
    data['deliveryFee'] = '10';
  }

  if (type === 'edit') {
    const id = data['id'];
    delete data['id'];
    delete data['isChildProduct'];

    putApiCall(
      `${endPoints.product.productVariant.updateVariant(id)}`,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (data) {
          notify('Variant Updated Successfully.', 'success');
          cb();
        }
        // if (
        //   data &&
        //   statusCode &&
        //   (statusCode === apiSuccessCode.created ||
        //     statusCode === apiSuccessCode.accepted)
        // ) {
        //   cb();
        // }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  } else {
    postApiCall(
      `${endPoints.product.productVariant.createVariant}`,
      data,
      (s: any) => {
        const {
          data: { statusCode, message },
        } = s;
        if (data) {
          notify(message, 'success');
          cb();
        }
        // if (
        //   data &&
        //   statusCode &&
        //   (statusCode === apiSuccessCode.created ||
        //     statusCode === apiSuccessCode.accepted)
        // ) {
        //   cb();
        // }
        dispatch(updateActionApiStatus(ApiStatus.succeeded));
      },
      (e: any) => {
        handleError(e);
      },
    );
  }
};

export const deleteProductVariant = (id: string, cb?: any) => {
  const { dispatch } = store;

  deleteApiCall(
    `${endPoints.product.productVariant.deleteProductVariant(id)}`,
    {},
    (d: any) => {
      notify('Variant deleted successfully', 'success');
      cb();
      // actionLoader(ApiStatus.succeeded);
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const deleteAlreadyVariant = (
  productId: string,
  id: string,
  cb?: any,
) => {
  const { dispatch } = store;

  deleteApiCall(
    `${endPoints.product.productVariant.deleteAlreadyVariant(productId, id)}`,
    {},
    (d: any) => {
      notify('Variant deleted successfully', 'success');
      cb(d);
      // actionLoader(ApiStatus.succeeded);
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const delayLoadingProduct = () => {
  const { dispatch } = store;
  setTimeout(() => {
    dispatch(updateActionApiStatus(ApiStatus.idle));
  }, 1000);
};

export const getProductsByIds = (fromChat: boolean = false) => {
  const { getState, dispatch } = store;
  dispatch(setProductLoader(true));
  let query = '';
  const {
    product: { selectedIds },
    chat: { selectedIds: chatSelected, productsAndServiceList },
  }: RootState = getState();

  if (fromChat) {
    chatSelected.forEach((id: string, index: number) => {
      if (
        productsAndServiceList[index] &&
        productsAndServiceList[index].packageType
      ) {
        query += `packageId=${id}&`;
      } else {
        query += `productId=${id}&`;
      }
    });
  } else {
    selectedIds.forEach((id: string) => {
      query += `productId=${id}&`;
    });
  }
  dispatch(setSelectedProductList(null));
  query &&
    getApiCall(
      `${endPoints.order.productDetails}?${query}`,
      (s: any) => {
        const { data: data } = s;
        if (data.data) {
          dispatch(setSelectedProductList(data.data));
        }
        dispatch(setProductLoader(false));
      },
      (e: any) => {
        if (e.data && e.data.message) {
          notify(e.data.message, 'error');
        } else {
          notify(`Error while fetching  list`, 'error');
        }
        if (e.data && e.data.statusCode === 401) {
          logout();
        }
        dispatch(setProductLoader(false));
      },
    );
};

export const handleVariantScroll = (status: boolean) => {
  const { dispatch } = store;
  dispatch(setVariantScroll(status));
};
