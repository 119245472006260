import React, { ChangeEvent } from 'react';
import { Box, FormLabel, LinearProgress, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomPopover from './CustomPopover';
import { notify } from '../Utils/toastify';
import { commonUploadFn } from '../Utils/AwsService';
import withDragDrop from './WithDragAndDrop';
interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?:
  | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  | undefined;
  value?: string;
  startAdornment?: React.ReactElement | null;
  endAdornment?: React.ReactElement | null;
  control?: null | any;
  tooltip?: boolean;
  popoverContent?: React.ReactElement | null;
  endAdornmentIcon?: React.ReactElement;
  setValue?: any;
  sizeLimit?: number;
  sizeLimitError?: string;
  showCard?: boolean;
  s3Upload?: boolean;
  s3fileUrl?: string;
  setS3FileUrl?: (url: string) => void;
  requiredField?: boolean | any;
  schema?: any;
  onDrop?: (files: FileList) => void;
  progress: number;
  setProgress?: any;
  clearErrors?: any;
  isImage?: any | boolean;
}

const FileInput: React.FC<Props> = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    helperText,
    error,
    disabled,
    defaultValue,
    variant,
    value,
    onBlur,
    startAdornment,
    endAdornment,
    onChange,
    control,
    tooltip,
    popoverContent,
    endAdornmentIcon,
    setValue,
    sizeLimit = 3 * 1024 * 1024,
    showCard = false,
    sizeLimitError = 'File size exceed',
    s3Upload = false,
    setS3FileUrl,
    s3fileUrl,
    requiredField,
    schema,
    progress,
    setProgress,
    clearErrors,
    isImage,
  } = props;

  const imageRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleDelete = () => {
    if (s3fileUrl) {
      setS3FileUrl && setS3FileUrl('');
    }
    setValue(name, undefined);
  };

  const handleUpload = (file: File) => {
    commonUploadFn(file, setProgress, (url) => {
      if (url) {
        setS3FileUrl && setS3FileUrl(url);
        clearErrors && clearErrors(name);
      } else {
        setValue(name, undefined);
      }
      setProgress(0);
    });
  };

  const imageFormats: Array<any> = ['jpeg', 'png', 'jpg', 'PNG', 'JPEG'];

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {

    const files: any = e.target.files;
    console.log(files, "wjkbfkw")

    if (isImage) {
      let fileType = files[0]?.type?.split('/')[1]?.toLowerCase();
      if (
        !imageFormats.includes(fileType)
      ) {
        notify('Format allowed .png,.jpg,.jpeg', 'error');

        return;
      }
    }
    if (files?.length && files?.length > 0) {
      if (s3fileUrl) {
        handleDelete();
      }
      if (files[0].size <= sizeLimit) {
        if (setValue) {
          setValue(name, files[0]);
        }
        if (schema) {
          const file = files[0];
          try {
            await schema.validateAt(name, { [name]: file });
          } catch (error: any) {
            error && error.message && notify(error.message, 'error');
            setValue(name, null);
            return;
          }
        }
        if (s3Upload) {
          handleUpload(files[0]);
        }
      } else {
        notify(sizeLimitError, 'error');
        return;
      }
    }
    e.target.value = '';
  };

  return (
    <Box>
      <FormLabel className="formLabel">
        {label} {requiredField ? <sup>*</sup> : null}
        {tooltip ? (
          <>
            <CustomPopover
              id=""
              children={popoverContent}
              arrow
              anchorOrigin_vertical="top"
              anchorOrigin_horizontal="center"
              transformOrigin_vertical="bottom"
              transformOrigin_horizonral="center"
            />
          </>
        ) : null}
      </FormLabel>
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <div className="fileSelection">
                <input
                  name="picture"
                  type="file"
                  ref={imageRef}
                  aria-hidden="true"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".jpg , .jpeg , .png , .pdf, .doc , .docx"
                  className="dddd"
                />
                <TextField
                  id={id}
                  type="text"
                  helperText={progress === 0 ? helperText : ''}
                  variant={variant}
                  error={error}
                  placeholder={placeholder}
                  disabled={disabled}
                  color="primary"
                  size="small"
                  {...field}
                  value={s3fileUrl && field.value ? field.value.name : ''}
                  fullWidth
                  onClick={handleClick}
                  InputProps={{
                    startAdornment,
                    autoComplete: 'off',
                    readOnly: true,
                    endAdornment,
                  }}
                  className="fileInputField"
                />
                {showCard && (progress !== 0 || s3fileUrl) && (
                  <div className="fileProgress">
                    {/* {field.value && s3fileUrl && (
                      <div className="progressInfo">
                        <div className="fileIc">
                          <img src={Images.FILE_ICON} alt="File" />
                        </div>
                        <div
                          className="doc_name"
                          onClick={() => window.open(s3fileUrl)}
                        >
                          <h4>{field.value ? field.value.name : ''}</h4>
                          <p>{calculateFileSize(field.value)}</p>
                        </div>
                        <IconButton
                          className="icButton"
                          onClick={() => {
                            handleDelete()
                          }}
                        >
                          <img src={Images.DELETE_IC} alt="Delete" />
                        </IconButton>
                      </div>
                    )} */}

                    {progress !== 0 && progress !== 100 && (
                      <div className='linearProgress'>
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                        />
                        <p>{progress}%</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          }}
        />
      ) : (
        <>
          <input
            name="picture"
            type="file"
            ref={imageRef}
            aria-hidden="true"
            style={{ display: 'none' }}
            onChange={(e: any) => { }}
            accept=".jpg , .jpeg , .png , .svg , .webp"
            className="dddd"
          />
          <TextField
            id={id}
            size="small"
            name={name}
            type="text"
            helperText={helperText}
            variant={variant}
            error={error}
            placeholder={placeholder}
            disabled={disabled}
            color="primary"
            // value={value || ''}
            // onChange={onChange}
            defaultValue={defaultValue}
            fullWidth
            onClick={handleClick}
            InputProps={{
              startAdornment,
              autoComplete: 'off',
              endAdornment,
              readOnly: true,
            }}
            className="fileInputField"
          />
        </>
      )}
    </Box>
  );
};

FileInput.defaultProps = {
  placeholder: '',
  error: false,
  disabled: false,
  variant: 'outlined',
  startAdornment: null,
  endAdornment: null,
};
export const FileInputWithDND = withDragDrop(FileInput);
export default FileInput;
