import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import {
  apiSuccessCode,
  getApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import { ApiStatus, ClientState } from '../types';
import { logout } from './auth.slice';
import store, { RootState } from './store';

const initialState: ClientState = {
  clientList: {},
  clientInfo: {},
  sets: {},
  setDetails: {},
  setOrders: {},
  showMultipleOrdersStatus: false,
  selectedSetOrders: [],
  rateAndDiscount: {},
  documents: {},
  associationsList: {},
  page: 1,
  searchKeywords: '',
  type: '',
  status: '',
  loading: false,
  filter: {
    toDate: null,
    fromDate: null,
    type: '',
    status: '',
  },
  editStatus: false,
  setOrderLoading: false,
};

const ClientManagement: any = createSlice({
  name: 'Client management',
  initialState,
  reducers: {
    updateClientManagement: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
    resetClientManagement: (state, action: any) => {
      return initialState;
    },
  },
});

export const { updateClientManagement, resetClientManagement } =
  ClientManagement.actions;

export default ClientManagement.reducer;

export const getClients = (params?: any) => {
  const { dispatch } = store;

  dispatch(updateClientManagement({ loading: true }));
  const { page, searchKeywords, filter } = store.getState().client;
  const { toDate, fromDate, type, status } = filter;

  console.log(searchKeywords);

  getApiCall(
    `${endPoints.client.getClientList}?pageNo=${page}&limit=10${
      type && '&clientType=' + type
    }${searchKeywords && '&searchKey=' + searchKeywords}${
      status ? '&status=' + status?.toUpperCase() : ''
    }
    `,
    (e: any) => {
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD =>', data);
        dispatch(updateClientManagement({ clientList: data?.data }));
      }
      dispatch(updateClientManagement({ loading: false }));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const getSets = (id?: any) => {
  const { dispatch } = store;

  const { page } = store.getState().client;
  dispatch(updateClientManagement({ loading: true }));

  getApiCall(
    `${endPoints.client.getSet}?productionId=${id}&pageNo=${page}&limit=10`,
    (e: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD =>', data);
        dispatch(updateClientManagement({ sets: data?.data }));
        dispatch(updateClientManagement({ loading: false }));
      }
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const getClientDetails = (id?: any) => {
  const { dispatch } = store;

  getApiCall(
    `${endPoints.client.getClientInfo(id)}`,
    (e: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD details =>', data);
        dispatch(updateClientManagement({ clientInfo: data?.data }));
      }
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const getSetDetails = (id?: any) => {
  const { dispatch } = store;

  getApiCall(
    `${endPoints.client.getSetDetails}?setId=${id}&pageNo=${1}&limit=10`,
    (e: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const { data } = e;
      if (data && data.data) {
        // console.log('set details =>', data);
        dispatch(updateClientManagement({ setDetails: data?.data }));
      }
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const getSetOrders = (id?: any) => {
  const { dispatch } = store;

  const { page, searchKeywords } = store.getState().client;

  dispatch(updateClientManagement({ setOrderLoading: true }));

  getApiCall(
    `${endPoints.client.getSetOrders}?setIds=${id}&pageNo=${page}&limit=10${
      searchKeywords && '&searchKey=' + searchKeywords
    }`,
    (e: any) => {
      dispatch(updateClientManagement({ setOrderLoading: false }));
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD details =>', data);
        dispatch(updateClientManagement({ setOrders: data?.data }));
        dispatch(updateClientManagement({ setOrderLoading: false }));
      }
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ setOrderLoading: false }));
    },
  );
};

export const updateMultipleOrderStatus = (status: boolean) => {
  const { dispatch } = store;
  dispatch(updateClientManagement({ showMultipleOrdersStatus: status }));
};

export const selectedSetIds = (ids: any) => {
  const { dispatch } = store;
  dispatch(updateClientManagement({ selectedSetOrders: ids }));
};

export const removeSelectedIds = () => {
  const { dispatch } = store;
  dispatch(updateClientManagement({ selectedSetOrders: [] }));
};

export const editStatusHandle = (status: boolean) => {
  const { dispatch } = store;
  dispatch(updateClientManagement({ editStatus: status }));
};

// export const getMultipleSetOrders = (id?: any) => {
//   const { dispatch } = store;

//   getApiCall(
//     `${endPoints.client.getSetOrders}?setIds=${id}&pageNo=${1}&limit=10`,
//     (e: any) => {
//       dispatch(updateClientManagement({ loading: false }));
//       const { data } = e;
//       if (data && data.data) {
//         console.log('DDDD details =>', data);
//         dispatch(updateClientManagement({ setOrders: data?.data }));
//       }
//     },
//     (e: any) => {
//       if (e.data && e.data.message) {
//         notify(e.data.message, 'error');
//       } else {
//         notify(`Error while fetching company details`, 'error');
//       }
//       if (e.data && e.data.statusCode === 401) {
//         logout();
//       }
//       dispatch(updateClientManagement({ loading: false }));
//     }
//   );
// };

export const postRatesAndDiscount = (payload?: any, cb?: any) => {
  const { dispatch } = store;

  // console.log(payload);

  postApiCall(
    endPoints.client.postRateAndDiscount,
    payload,
    (s: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        // console.log('DD =>', s);
        // notify(', 'success');
        notify('Rates and Discount has been added successfully.', 'success');
        cb();
      }
    },
    (e: any) => {
      // handleError(e);
      // cb(false);
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const updateRatesAndDiscount = (payload?: any, cb?: any) => {
  const { dispatch } = store;

  // console.log(payload);

  putApiCall(
    endPoints.client.updateRateAndDiscount,
    payload,
    (s: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        // console.log('DD =>', s);
        notify('Rates and Discount has been updated successfully.', 'success');
        cb();
        // notify(', 'success');
      }
    },
    (e: any) => {
      // handleError(e);
      // cb(false);
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const getRateAndDiscount = (id?: any, cb?: any) => {
  const { dispatch } = store;

  // console.log('Workng');

  dispatch(updateClientManagement({ loading: true }));

  getApiCall(
    `${endPoints.client.getRateAndDiscount(id)}`,
    (e: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD details =>', data);
        dispatch(updateClientManagement({ rateAndDiscount: data?.data }));
        cb(data?.data);
        dispatch(updateClientManagement({ loading: false }));
      }
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const postDocument = (payload?: any, cb?: any) => {
  const { dispatch } = store;

  // console.log(payload);

  postApiCall(
    endPoints.client.postDocument,
    payload,
    (s: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        // console.log('DD =>', s);
        notify('Document uploaded successfully', 'success');
        getDocuments(payload.productionId);
        cb();
      }
    },
    (e: any) => {
      // handleError(e);
      // cb(false);
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const getDocuments = (id?: any) => {
  const { dispatch } = store;

  const { page } = store.getState().client;

  dispatch(updateClientManagement({ loading: true }));

  getApiCall(
    `${
      endPoints.client.getDocuments
    }?productionId=${id}&pageNo=${page}&limit=${5}`,
    (e: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD details =>', data);
        dispatch(updateClientManagement({ documents: data?.data }));
        dispatch(updateClientManagement({ loading: false }));
      }
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const getAssociationsList = (id?: any) => {
  const { dispatch } = store;

  const { page } = store.getState().client;

  dispatch(updateClientManagement({ loading: true }));

  getApiCall(
    `${endPoints.client.getAssociations(id)}?&pageNo=${page}&limit=${10}`,
    (e: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD details =>', data);
        dispatch(updateClientManagement({ associationsList: data?.data }));
        dispatch(updateClientManagement({ loading: false }));
      }
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const removeDiscout = (id?: any, cb?: any) => {
  const { dispatch } = store;

  // console.log(payload);

  putApiCall(
    endPoints.client.removeDiscout(id),
    {},
    (s: any) => {
      dispatch(updateClientManagement({ loading: false }));
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        // console.log('DD =>', s);
        notify('Discount remove successfully', 'success');
        // getDocuments(payload.productionId);
        cb();
      }
    },
    (e: any) => {
      // handleError(e);
      // cb(false);
      dispatch(updateClientManagement({ loading: false }));
    },
  );
};

export const defaultPage = (newPage?: number) => {
  const { dispatch } = store;

  dispatch(updateClientManagement({ page: newPage || 1 }));
};
