import { useRef, useState } from 'react';

interface DragDropProps {
  onDrop?: (files: FileList) => void;
}

const withDragDrop = <P extends DragDropProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const WithDragDrop: React.FC<P> = (props) => {
    const [isDragging, setIsDragging] = useState(false);
    const dropRef = useRef<HTMLDivElement>(null);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = () => {
      setIsDragging(false);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(false);

      const files = e.dataTransfer.files;
      if (files.length > 0) {
        props.onDrop && props.onDrop(files);
        // Trigger the onChange event after drop
      }
    };

    return (
      <div className={`dragNdrop ${isDragging && 'dragging'}`}>
        <div
          className="dragContainer"
          ref={dropRef}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  };

  return WithDragDrop;
};

export default withDragDrop;
