const endPoints = {
  sendotp: 'user-service/reel/api/v1/vendor/send-otp',
  resetpassword: 'user-service/reel/api/v1/vendor/reset-password',
  register: 'user-service/reel/api/v1/vendor/signup',
  login: 'user-service/reel/api/v1/vendor/login',
  logout: 'user-service/reel/api/v1/vendor/logout',
  verify: 'user-service/reel/api/v1/vendor/verify-otp',
  businessRegister: 'user-service/reel/api/v1/vendor/business/registration',
  common: {
    deparmentList: 'vendor-service/api/v1/dept-list',
    countryList: 'user-service/reel/api/v1/user/country-list',
    categories: 'vendor-service/api/v1/categories',
  },
  staticData: {
    content: 'vendor-service/api/v1/static-content/',
  },
  chat: {
    getQuote: 'vendor-service/api/v1/quotation/',
    createChat: 'vendor-service/api/v1/user/chat',
    employeeList: 'vendor-service/api/v1/user/chat/vendors',
  },
  product: {
    create: 'vendor-service/api/v1/products',
    list: 'vendor-service/api/v1/products',
    edit: 'vendor-service/api/v1/products/',
    delete: 'vendor-service/api/v1/products/',
    update: 'vendor-service/api/v1/products/',
    statusUpdate: 'vendor-service/api/v1/products/',
    templateList: 'vendor-service/api/v1/product/templates',
    sequenceList: 'vendor-service/api/v1/products/sequence',
    package: {
      create: 'vendor-service/api/v1/packages',
      list: 'vendor-service/api/v1/packages',
      edit: 'vendor-service/api/v1/packages/',
      delete: 'vendor-service/api/v1/packages/',
      update: 'vendor-service/api/v1/packages/',
      statusUpdate: 'vendor-service/api/v1/packages/',
      templateList: 'vendor-service/api/v1/packages/templates',
      sequenceList: 'vendor-service/api/v1/packages/sequence',
    },
    productVariant: {
      createVariant: 'vendor-service/api/v1/variant-products',
      updateVariant: (id: string) =>
        `vendor-service/api/v1/variant-products/${id}`,
      deleteProductVariant: (id: string) =>
        `vendor-service/api/v1/products/product-variant/${id}`,
      // -/products/{productId}/variant/property/{id}
      deleteAlreadyVariant: (productId: string, id: string) =>
        `vendor-service/api/v1/products/${productId}/variant/property/${id}`,
    },
  },
  order: {
    list: 'vendor-service/api/v1/order',
    cancleOrder: 'vendor-service/api/v1/order/cancel',
    productItems: 'vendor-service/api/v1/order/items',
    updateQuantity: 'vendor-service/api/v1/order/quantity',
    updateRentQuantity: (id: string) => `vendor-service/api/v1/order/${id}/qty`,
    updateStatus: 'vendor-service/api/v1/order/status',
    viewWorksheet: (id: string) =>
      `vendor-service/api/v1/order/products/${id}/worksheet`,
    extend_service: (id: string) =>
      `vendor-service/api/v1/order/products/${id}/extend-service`,
    updateShift: (id: string) => `vendor-service/api/v1/order/shifts/${id}`,
    updateStatusShift: (id: string) =>
      `vendor-service/api/v1/order/shifts/${id}/accept-reject`,
    addProducts: (id: string) => `vendor-service/api/v1/order/${id}/add-more`,
    updateAddress: 'vendor-service/api/v1/order/address',
    rentApproveReject: 'vendor-service/api/v1/order/',
    productDetails: 'vendor-service/api/v1/order/products',
    verifyRentalDetails: 'vendor-service/api/v1/chat/service/validate-time',
    createCart: 'vendor-service/api/v1/cart',
    addItemServicesToOrder: `vendor-service/api/v1/order/add-more-service`,
    updateCartOrDelete: `vendor-service/api/v1/cart`,
    quotationCart: 'vendor-service/api/v1/quotation_product/cart',
    quotationShifts: 'vendor-service/api/v1/quotation_cart/shifts',
    quotationCartDetails: 'vendor-service/api/v1/quotation_cart/',
    cartRentalDetailsUpdate: (id: string) =>
      `vendor-service/api/v1/cart/${id}/service-period`,
    getOrderStatus: (id: string) => `vendor-service/api/v1/orders/${id}/status`,
    draft: {
      CRUD: `vendor-service/api/v1/order-draft`,
      addProducts: `vendor-service/api/v1/vendor-cart/products`,
      createOrder: `vendor-service/api/v1/vendor-order`,
      createCartId: `vendor-service/api/v1/vendor-cart`,
    },
  },
  company: {
    getOrPut: 'vendor-service/api/v1/companies',
  },
  client: {
    getClientList: 'vendor-service/api/v1/vendor/client',
    getSet: 'vendor-service/api/v1/vendor/client/set',
    getClientInfo: (id: string) => `vendor-service/api/v1/vendor/client/${id}`,
    getSetDetails: `vendor-service/api/v1/vendor/client/set-location`,
    getSetOrders: `vendor-service/api/v1/vendor/client/set-order`,
    getRateAndDiscount: (id: string) =>
      `vendor-service/api/v1/vendor/client/discount/${id}`,
    postRateAndDiscount: 'vendor-service/api/v1/vendor/client/discount',
    updateRateAndDiscount: 'vendor-service/api/v1/vendor/client/discount',
    postDocument: 'vendor-service/api/v1/vendor/client/document',
    getDocuments: 'vendor-service/api/v1/vendor/client/document',
    getAssociations: (id: string) =>
      `vendor-service/api/v1/vendor/client/associations/${id}`,
    removeDiscout: (id: string) =>
      `vendor-service/api/v1/vendor/client/discount/${id}`,
  },
  branch: {
    CRUD: 'vendor-service/api/v1/branches',
    managerAddOrUpdate: 'vendor-service/api/v1/branches/managers',
    zones: 'vendor-service/api/v1/branches/zones',
  },
  profile: {
    getOrUpdate: 'vendor-service/api/v1/vendors/settings',
    updatePass: 'user-service/reel/api/v1/vendor/update-password',
  },
  //Co-vendor name changed to employ
  coVendor: {
    permissionTable: 'vendor-service/api/v1/co-vendors/permissions',
    vendorCRUD: 'vendor-service/api/v1/co-vendors',
  },
  notification: {
    updateDeviceToken: '/notification-service/reel/api/v1/user/apns/token',
    updateDeviceTokenFCM: '/notification-service/reel/api/v1/vendor/apns/token',
    notifications: '/notification-service/reel/api/v1/vendor/notifications',
  },
  riskmanagement: {
    risklist: 'risk-manager-service/reel/api/v1/vendor/securityManager',
    addriskManager:
      'risk-manager-service/reel/api/v1/vendor/securityManager/add',
    userDetails: 'risk-manager-service/reel/api/v1/vendor/securityManager',
    updateRiskManager:
      'risk-manager-service/reel/api/v1/vendor/securityManager/edit/',
    deleteExperience:
      'risk-manager-service/reel/api/v1/vendor/securityManager/experience',
    blockUnblock:
      'risk-manager-service/reel/api/v1/vendor/securityManager/status/',
    entityListing:
      'risk-manager-service/reel/api/v1/vendor/securityManager/entity',
    countryCityListing:
      'risk-manager-service/reel/api/v1/securityManager/cities',
    countryList: 'risk-manager-service/reel/api/v1/securityManager/country',
    departmentList:'risk-manager-service/reel/api/v1/vendor/securityManager/department',
    studioList :'/risk-manager-service/reel/api/v1/vendor/securityManager/studio',
    productionList:'/risk-manager-service/reel/api/v1/vendor/securityManager/production',
    companyList:'/risk-manager-service/reel/api/v1/vendor/securityManager/company',
    university:'/risk-manager-service/reel/api/v1/vendor/securityManager/university'

  },
};

export default endPoints;
