import React, { KeyboardEvent, RefObject, useEffect } from 'react';
import { regexForZipcode } from './yupvalidations';
import { ProductType } from '../types';
import { PRODUCT_TYPES, SHIFT_COVERAGE } from './constantData';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { notify } from './toastify';

import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

var CryptoJS = require('crypto-js');

export const keyPhrase = 'reel_secret$123';

// Encrypt
export const encryptData = (data: any, keyPhrase: string) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), keyPhrase).toString();

// Decrypt
export const decryptData = (data: any, keyPhrase: string) => {
  var bytes = CryptoJS.AES.decrypt(data, keyPhrase);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const useDebounce = (value: any, delay: any) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export function generateRandomString(length: number): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const timestamp = new Date().getTime().toString(); // Get the current timestamp
  const randomCharacters = [];

  // Generate random characters
  while (randomCharacters.length < length - timestamp.length) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomCharacters.push(characters.charAt(randomIndex));
  }

  // Combine random characters with the timestamp
  const randomString = randomCharacters.join('') + timestamp;

  return randomString;
}

export function calculatePercentageAndSubtract(
  total: number,
  percentage: number,
): number {
  // Calculate the amount to subtract
  const amountToSubtract = (percentage / 100) * total;

  // Subtract the percentage from the total
  const result = total - amountToSubtract;

  return result;
}

export function toTitleCase(input: string): string {
  return input?.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export async function fetchLocation(code: string) {
  const apiKey = process.env.GOOGLE_API_KEY; // Replace with your actual API key

  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${code}&key=${apiKey}`,
  );
  return response.json();
}

export const validateZipCode = (code: string): boolean =>
  regexForZipcode.test(code);

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
) {
  let timeoutId: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
      timeoutId = null;
    }, delay);
  };
}

// A generic throttle function
export function throttle<T extends (...args: any[]) => any>(
  func: T,
  limit: number,
) {
  let lastCallTime = 0;

  return (...args: Parameters<T>) => {
    const now = Date.now();

    if (now - lastCallTime >= limit) {
      func(...args);
      lastCallTime = now;
    }
  };
}

export function getFileNameFromUrl(url: string): string | null {
  try {
    const urlObject = new URL(url);
    const path = urlObject.pathname;
    const segments = path.split('/');
    const fileName = segments[segments.length - 1];
    return fileName;
  } catch (error: any) {
    return null;
  }
}

const shiftCoverageList = Object.keys(SHIFT_COVERAGE);
/**
 *
 * @param startTime
 * @param endTime
 * @returns string
 */
export function isDayOrNight(startTime: number, endTime: number): string {
  // Convert times to 24-hour format (if needed)
  const startHour = startTime % 24;
  const endHour = endTime % 24;

  // Define day and night hours (adjust based on your location and season)
  const dayStartHour = 7; // 7:00 AM
  const nightStartHour = 19; // 7:00 PM

  // Check if the start time falls within day or night hours
  if (startHour >= dayStartHour && startHour < nightStartHour) {
    return shiftCoverageList[0];
  } else if (endHour >= dayStartHour && endHour < nightStartHour) {
    return shiftCoverageList[0];
  } else {
    return shiftCoverageList[1];
  }
}

/**
 *
 * @param formRef
 * @param errors
 */
export function scrollErorrElementInView(
  formRef: RefObject<HTMLFormElement>,
  errors: any,
) {
  if (Object.keys(errors).length > 0) {
    const errorField = formRef.current?.querySelector(`.Mui-error`);
    if (errorField) {
      errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}

/**
 *
 * @param type
 * @returns string
 */
export const getProductType = (type: string) => {
  if (type === ProductType.PURCHASE) return PRODUCT_TYPES.purchase;
  else if (type === ProductType.RENTAL) return PRODUCT_TYPES.rental;
  else if (type === ProductType.RENTAL_AND_PURCHASE)
    return PRODUCT_TYPES.rentAndPurchase;
  else return '-';
};

/**
 *
 * @param startDate
 * @param startTime
 * @returns
 */
export const generateTimeStamp = (startDate: any, startTime: any) => {
  var newStartDate = `${new Date(startDate).getFullYear()}-${
    new Date(startDate).getMonth() + 1
  }-${new Date(startDate).getDate()}`;
  var newStartTime = `${new Date(startTime).getHours()}:${new Date(
    startTime,
  ).getMinutes()}`;

  var orderStartTime = dayjs(newStartDate + ' ' + newStartTime)
    .toDate()
    .getTime();
  return orderStartTime;
};

export const formatPrice = (price: string): string => {
  return Number.isNaN(parseInt(price)) ? '0' : parseFloat(price).toFixed(2); // toFixed() method converts a number into a string, rounding to a specified number of decimal places
};

export const getTimeWithTimeZoneAbbreviation = (
  dateString: any,
  format?: string,
) => {
  const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedDate = moment
    .utc(dateString)
    .tz(deviceTimeZone)
    .format(format ? format : 'MMM D, YYYY  hh:mm A (z)');
  return formattedDate;
};

export const decryptDataWithKey = (cipherText: any, privateKey: string) => {
  let decryptedData = '';
  try {
    if (!privateKey || !cipherText) {
      return '';
    }
    let bytes = CryptoJS.AES.decrypt(cipherText, privateKey);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return decryptedData || '';
  }
  return decryptedData || '';
};

export const getVideoCover = (file: File, seekTo = 0.0) => {
  console.log('getting video cover for file: ', file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', (_) => {
      reject('error when loading video file');
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        console.log('video is now paused at %ss.', seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('No context');
          return;
        }
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          'image/jpeg',
          0.75 /* quality */,
        );
      });
    });
  });
};
export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const isValidIntegerKey = (key: string) => {
  const integerKeyRegex = /^[0-9]$/;
  return integerKeyRegex.test(key);
};

export const isValidDecimalKey = (key: string, currentValue: string) => {
  const decimalKeyRegex = /^[0-9.]$/;
  if (!decimalKeyRegex.test(key)) {
    return false;
  }

  // Ensure only one dot is allowed
  if (key === '.' && currentValue.includes('.')) {
    return false;
  }

  return true;
};

export const capitalizeFirstLetter = (str: any) => {
  if (!str) {
    return '';
  }
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export function getFileNameFromAWSURL(url: string) {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1]?.replace(/\d+/g, '');
}
/**
 * Checks if any string from the array exists in the given value.
 *
 * @param arr - Array of strings to check against.
 * @param value - The string value to be checked.
 * @returns True if any string from the array exists in the value, false otherwise.
 */
export function containsAnyString(arr: string[], value: string): boolean {
  return arr.some((str) => value.includes(str));
}

/**
 * Truncates the input text if it exceeds the specified max length
 * and appends an ellipsis ('...') to the end of the truncated text.
 *
 * @param text - The input text to be truncated.
 * @param maxLength - The maximum allowed length of the text before truncation.
 * @returns The truncated text with an ellipsis if truncation occurs.
 */
export function truncateWithEllipsis(
  text: string | undefined | null,
  maxLength: number = 40,
): string {
  if (!text) {
    return ''; // Return an empty string if text is undefined or null
  }

  if (text.length <= maxLength) {
    return text;
  }

  return text.slice(0, maxLength - 3) + '...';
}

interface Entity {
  _id: number; // Adjust type as per your actual object structure
  // Add other properties here if needed
}

export function filterArrayBySecondArray(
  firstArray: Entity[],
  secondArray: Entity[],
): Entity[] {
  // Convert secondArray into a Set for faster lookups
  const idArr = secondArray.map((entity) => entity._id);

  // Filter firstArray based on whether id exists in idSet
  const filteredArray = firstArray.filter((entity) =>
    idArr.includes(entity._id),
  );

  return filteredArray;
}

///Using to disable all forms on hitting enter
export const preventSubmitOnEnter = (e: KeyboardEvent<HTMLFormElement>) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

/**
 * Removes the string after the second '/' in the input string.
 * @param input - The input string.
 * @returns The string with content after the second '/' removed.
 */
export function removeAfterSecondSlash(input: string): string {
  const parts = input.split('/');
  if (parts.length > 2) {
    return parts.slice(0, 2).join('/');
  }
  return input;
}

export function getMinFlatDiscountCap(rentPricing: any): number | undefined {
  let capVal: number | undefined = undefined;

  rentPricing &&
    rentPricing.forEach((pricing: any) => {
      const { rentPrice } = pricing;

      if (capVal == null) {
        capVal = rentPrice;
      } else if (rentPrice !== undefined && capVal > +rentPrice) {
        capVal = rentPrice;
      }
    });
  return capVal !== undefined ? +capVal : capVal;
}

export function generateShortKey(length: number = 8): string {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length);
}

export const downloadZipFromS3 = async (urls: any, name: string, cb?: any) => {
  const zip: any = new JSZip();
  const folder: any = zip.folder(name || 'files');

  const fetchFile = async (url: any) => {
    try {
      const response = await axios.get(url, { responseType: 'arraybuffer' });
      const fileName = url.split('/').pop(); // Extract the file name from the URL
      folder.file(fileName, response.data);
    } catch (error) {
      console.error(`Failed to fetch ${url}`, error);
    }
  };

  // Fetch all files and add them to the zip
  await Promise.all(urls.map((url: any) => fetchFile(url)));

  // Generate the zip file and trigger the download
  zip.generateAsync({ type: 'blob' }).then((content: any) => {
    saveAs(content, name || 'files.zip');
  });
};
