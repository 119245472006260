import { Chat, ContactDetails } from '../types';

export function calculateFileSize(file: File): string {
  const bytes = file.size;

  if (bytes === 0) {
    return '';
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}

export const convertLinkString = (input: string) => {
  const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const matches = input.match(urlRegex);
  let output = input;
  if (!matches) {
    return input;
  }
  for (let match of matches) {
    const protocolRegex = /^(?:https?|ftp):\/\//.test(match);
    output = output.replace(
      match,
      `<a href="${
        !protocolRegex && 'https://'
      }${match}" target="_new" rel="noopener noreferrer">${match}</a>`,
    );
  }
  return output;
};

export class ChatBuilder {
  private chat: Chat;
  constructor(chatId: string) {
    this.chat = {
      _id: chatId,
      members: [],
      type: 'ONE_TO_ONE',
      businessChatType: 'user_vendor_chat',
      lastMessageDetail: {},
      lastMsgCreated: new Date().getTime(),
      contactDetails: {},
      noOfUnreadMessage: 0,
      userOnlineStatus: null,
      isNewChat: true,
    };
  }
  setBusinessChatType(chatType: string) {
    this.chat.businessChatType = chatType;
    return this;
  }
  setContactDetails(contactDetails: ContactDetails) {
    this.chat.contactDetails = contactDetails;
    return this;
  }
  addMembers(memberId: string) {
    this.chat.members?.push(memberId);
    return this;
  }
  getChat() {
    return this.chat;
  }
}

export const createChat = ({
  chatId,
  businessChatType,
  senderId,
  receiverId,
  contactDetails,
}: {
  chatId: string;
  senderId: string;
  receiverId: string;
  contactDetails: ContactDetails;
  businessChatType: string;
}) => {
  const newChat: Chat = new ChatBuilder(chatId)
    .addMembers(senderId)
    .addMembers(receiverId)
    .setBusinessChatType(businessChatType)
    .setContactDetails(contactDetails)
    .getChat();
  return {
    newChat,
  };
};
