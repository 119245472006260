import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Images from '../Utils/images';

// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyCu3pQ2rxalRAWIONNcxoYST8Cy7NJrveA';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}
interface Props {
  id?: string;
  labelText?: string;
  placeHolderText?: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  defaultValue?: string | any;
  disabled?: boolean;
  typedValue?: any;
  onChange?: any;
  options?: any;
  control?: null | any;
  setValue?: any;
  isError?: boolean;
  isCountryField?: boolean;
  multiple?: boolean;
  isCheckbox?: boolean;
  requiredField?: boolean;
  labelKey?: string;
  limit?: number;
  clearIcon?: boolean;
  disableClearable?: boolean;
  onInputChange?: any;
  setLatLong?: any;
  changeCallBack?: any;
  defaultInputVal?: string;
  isObject?: boolean;
  searchType?: 'place' | 'country';
  citiesWithCountry?: string | undefined;
}
export default function AutoCompleteLocation(props: Props) {
  const {
    id,
    labelText,
    placeHolderText,
    name = '',
    error,
    helperText,
    defaultValue,
    disabled,
    control,
    setValue,
    isCountryField = false,
    multiple,
    isCheckbox,
    requiredField,
    onChange,
    labelKey,
    limit = 1,
    clearIcon = false,
    disableClearable = false,
    onInputChange,
    setLatLong = false,
    changeCallBack,
    defaultInputVal,
    isObject,
    searchType = 'place', // Default to 'place'
    citiesWithCountry = undefined,
  } = props;

  const [typedValue, setTypedValue] = React.useState<PlaceType | null>(
    defaultValue,
  );

  const [inputValue, setInputValue] = React.useState(defaultInputVal);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          if (autocompleteService.current) {
            const requestOptions = {
              ...request,
              types:
                searchType === 'country'
                  ? ['(regions)']
                  : citiesWithCountry
                  ? ['(cities)']
                  : undefined,
              componentRestrictions: citiesWithCountry
                ? { country: citiesWithCountry }
                : {},
            };

            (autocompleteService.current as any).getPlacePredictions(
              requestOptions,
              callback
            );
          }
        },
        400,
      ),
    [searchType,citiesWithCountry]
  );
  React.useEffect(() => {
    if (typedValue == null && defaultValue) {
      setTypedValue(defaultValue);
      setOptions([defaultValue]);
      setInputValue(defaultValue.description);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(typedValue ? [typedValue] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (typedValue) {
          newOptions = [typedValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [typedValue, inputValue, fetch]);

  return (
    <FormControl
      fullWidth
      className="customAutocomplete"
      error={error ? true : false}
      id={id}
    >
      <FormLabel className="formLabel">
        {labelText} {requiredField ? <sup>*</sup> : null}{' '}
      </FormLabel>
      {control ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : null}
          render={({ field }) => {
            return (
              <Autocomplete
                popupIcon={<img src={Images.SEARCH_BLACK} alt="Search" />}
                className="disablePopUpIndicator"
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                placeholder={placeHolderText}
                autoComplete={false} // Disable browser autofill
                {...field}
                value={typedValue}
                filterSelectedOptions
                noOptionsText="No locations"
                onChange={(event: any, newValue: PlaceType | null) => {
                  if (newValue) {
                    const geocoder = new window.google.maps.Geocoder();
                    // Geocode the place using Place ID
                    geocoder.geocode(
                      { placeId: newValue.place_id },
                      (
                        results: google.maps.GeocoderResult[],
                        status: google.maps.GeocoderStatus,
                      ) => {
                        if (
                          status === google.maps.GeocoderStatus.OK &&
                          results[0]
                        ) {
                          const place = results[0];
                          changeCallBack && changeCallBack(place);

                          // Extract latitude and longitude from the geocoded place result
                          const location = place.geometry?.location;

                          const lat = location?.lat();
                          const lng = location?.lng();

                          // Include latitude and longitude in the value
                          const valueWithLatLng = {
                            description:
                              citiesWithCountry &&
                              newValue.structured_formatting &&
                              newValue.structured_formatting.main_text
                                ? newValue.structured_formatting.main_text
                                : newValue.description,
                            latitude: lat,
                            longitude: lng,
                            structured_formatting:
                              newValue.structured_formatting,
                          };

                          // Update options and typedValue
                          setOptions([valueWithLatLng, ...options]);
                          setTypedValue(valueWithLatLng);
                          if (setLatLong && valueWithLatLng.latitude) {
                            setLatLong({
                              lng: valueWithLatLng.longitude,
                              lat: valueWithLatLng.latitude,
                            });
                          }
                          // Call onChange with the value including latitude and longitude
                        } else {
                          console.error(
                            'Geocode was not successful for the following reason:',
                            status,
                          );
                        }
                      },
                    );
                    isObject
                      ? setValue(name, newValue)
                      : setValue(name, newValue?.description);
                  } else {
                    // If newValue is null, clear the typedValue and call onChange with null
                    setTypedValue(null);
                    setValue(name, null);
                    changeCallBack && changeCallBack(event, newValue);
                  }

                  // Update form value if using React Hook Form
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                size='small'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={inputValue}
                    fullWidth
                    placeholder={placeHolderText}
                    size='small'
                    autoComplete="new-city" // Use a non-standard value
                  />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option?.structured_formatting
                      ?.main_text_matched_substrings || [];

                  const parts = parse(
                    option?.structured_formatting?.main_text,
                    matches.map((match: any) => [
                      match.offset,
                      match.offset + match.length,
                    ]),
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: 'flex', width: 44 }}>
                          <LocationOnIcon sx={{ color: 'text.secondary' }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: 'calc(100% - 44px)',
                            wordWrap: 'break-word',
                          }}
                        >
                          {parts.map((part, index) => (
                            <Box
                              key={index}
                              component="span"
                              sx={{
                                fontWeight: part.highlight ? 'bold' : 'regular',
                              }}
                            >
                              {part.text}
                            </Box>
                          ))}
                          <Typography variant="body2" color="text.secondary">
                            {option?.structured_formatting?.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            );
          }}
        />
      ) : null}

      <FormHelperText className={error ? 'Mui-error' : ''}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}
