import AWS from 'aws-sdk';
import { decryptDataWithKey, keyPhrase } from './helperFns';
import { notify } from './toastify';
import { s3Url } from './constantData';

export const S3_BUCKET = process.env.S3_BUCKET_NAME;
const REGION = process.env.S3_REGION;
export const AWS_FOLDER_PATH = process.env.FOLDER_PATH;

AWS.config.update({
  apiVersion: 'latest',
  accessKeyId: decryptDataWithKey(process.env.AWS_ACCESS_KEY_ID, keyPhrase),
  secretAccessKey: decryptDataWithKey(
    process.env.AWS_SECRET_ACCESS_KEY,
    keyPhrase,
  ),
  region: 'us-east-1',
});
console.log('modee->');

export const reelBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
//After changing any config here, Make sure do to the in MediaUploadSection.tsx file
export const commonUploadFn = (
  file: File,
  setProgress: undefined | any = undefined,
  callBack: undefined | ((url: string | null) => void) = undefined,
) => {
  const name = `${Date.now()}_${file.name}`;
  const key = AWS_FOLDER_PATH + name;
  const params = {
    ACL: process.env.mode === 'preprod' ? 'private' : 'public-read', ///Change this to 'private' when move to Prod Env
    Body: file,
    Bucket: S3_BUCKET || '',
    Key: key,
    ContentType: file.type,
    ContentDisposition: 'attachment',
  };

  const upload = reelBucket
    .putObject(params)
    .on('httpUploadProgress', (evt) => {
      setProgress && setProgress(Math.round((evt.loaded / evt.total) * 100));
    });
  upload.send((err, data) => {
    const fullURL = s3Url + key;
    if (data && callBack) {
      callBack(fullURL);
    }
    if (err) {
      console.log('error->>', err.message);
      notify('Error while uploading, Please try again.', 'error');
      callBack && callBack(null);
    }
  });
  return upload;
};
interface UploadedFile {
  url: string;
  name: string;
}

export const uploadToS3 = async (
  file: File,
  setUploadProgressimage: any = undefined,
): Promise<UploadedFile> => {
  return new Promise((resolve, reject) => {
    const s3 = reelBucket;
    const name = `${Date.now()}_${file.name}`;
    const key = AWS_FOLDER_PATH + name;
    const params = {
      Bucket: S3_BUCKET || '',
      Key: key,
      Body: file,
      ACL: process.env.mode === 'preprod' ? 'private' : 'public-read', ///Change this to 'private' when move to Prod
    };

    const options = {
      partSize: 5 * 1024 * 1024, // 5MB parts
      queueSize: 4, // 4 concurrent uploads
    };

    const upload = s3.upload(params, options);

    upload.on('httpUploadProgress', (progress) => {
      const percentage = Math.round((progress.loaded / progress.total) * 100);
      setUploadProgressimage && setUploadProgressimage(percentage);
    });

    upload
      .promise()
      .then((data) => {
        const fullURL = s3Url + key;
        resolve({ url: fullURL, name: file.name });
        setUploadProgressimage && setUploadProgressimage(null); // Reset progress after successful upload
      })
      .catch((error) => {
        reject(error);
        setUploadProgressimage && setUploadProgressimage(null); // Reset progress after failed upload
      });
  });
};
