import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import Images from '../Utils/images';
import React, { useEffect, useMemo, useState } from 'react';
import store, { RootState, useAppSelector } from '../redux/store';
import { logoutCall } from '../redux/auth.slice';
import { resetAuthorizationToken } from '../api';
import MenuIcon from '@mui/icons-material/Menu';
import { setCommonMenu } from '../redux/common.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../helpers/contants';
import {
  FORM_STEPS,
  StaticRoutes,
  USER_ROLES,
  navigation,
} from '../Utils/constantData';
import WarningDialog from '../Components/WarningDialog';
import { AIT } from '../Utils/Localization';
import { useTranslation } from 'react-i18next';
import PushNotification from '../Components/PushNotification';
import InAppNotification from '../Components/InAppNotification';

export default function Header(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const [showWarning, setWarning] = useState<boolean>(false);

  const { userData, status } = useAppSelector((state: RootState) => state.auth);
  const { loading } = useAppSelector((state: RootState) => state.common);
  React.useEffect(() => {
    store.dispatch(setCommonMenu(false));
  }, []);

  React.useEffect(() => {
    if (
      userData &&
      userData.formNextStep !== FORM_STEPS.BILLING_DETAILS &&
      !StaticRoutes.includes(location.pathname)
    ) {
      userData.formNextStep === undefined
        ? navigate(ROUTES.WELCOME)
        : navigate(ROUTES.BUSINESSINFO);
    }
  }, [userData, location.pathname]);
  React.useEffect(() => {
    if (!status) {
      navigate(ROUTES.LOGIN);
    }
  }, [status]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      location.pathname === '/' &&
      userData.role === USER_ROLES.CO_VENDOR &&
      !('isReelVendor' in userData)
    ) {
      const permissionModules = Object.keys(userData.permissions);
      if (permissionModules.length > 0) {
        const moduleToNavigate = navigation.find(
          (m) => m.name === permissionModules[0],
        );
        if (moduleToNavigate) {
          navigate(moduleToNavigate.path[0]);
        } else {
          navigate(ROUTES.PROFILE);
        }
      } else {
        navigate(ROUTES.PROFILE);
      }
    } else if (userData.isReelVendor && location.pathname === '/') {
      navigate(ROUTES.RISK_MANAGEMENT);
    } else if (location.pathname === '/') {
      navigate(ROUTES.PRODUCTS);
    }
  }, [location.pathname]);

  const { t } = useTranslation('translation', {
    keyPrefix: 'header',
  });
  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  });
  const MemomisePopUp = useMemo(() => <InAppNotification />, []);

  return (
    <>
      <header className="header">
        <div
          className="logo"
          // onClick={() => {
          //   status ? navigate(ROUTES.DASHBOARD) : navigate(ROUTES.LOGIN);
          // }}
        >
          <a
            href={`${
              userData && userData.formNextStep !== FORM_STEPS.BILLING_DETAILS
                ? 'javascript:void(0)'
                : ROUTES.DASHBOARD
            }`}
          >
            <img src={Images.LOGO} alt={AIT().logo} />
          </a>
        </div>

        {status && (
          <div className="authAction">
            {(location.pathname !== ROUTES.BUSINESSINFO ||
              location.pathname !== ROUTES.WELCOME) &&
              MemomisePopUp}
            <div
              className={`user_action ${
                location.pathname === ROUTES.BUSINESSINFO ? 'business-info' : ''
              }`}
            >
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <div className="userDropdown">
                  {/* <Avatar
                className="avatar"
                alt="User name"
                src={DefaultProfileImg}
              /> */}
                  {/* IN CASE OF NO IMAGE AVAULABLE */}
                  {/* <Avatar>H</Avatar> */}
                  <div className="info">
                    <h4>
                      {userData?.firstName} {userData?.middleName}{' '}
                      {userData?.lastName}
                    </h4>
                    <p>{userData?.email}</p>
                  </div>
                  {open ? (
                    <img
                      src={Images.ARROW_DOWN}
                      alt={userData?.firstName}
                      className="rotate_180"
                    />
                  ) : (
                    <img src={Images.ARROW_DOWN} alt={userData?.firstName} />
                  )}
                </div>
              </Button>
              {open && (
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className="profile_dropdown"
                  MenuListProps={{
                    'aria-labelledby': 'user-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: -25,
                    horizontal: 'right',
                  }}
                >
                  {userData.formNextStep === FORM_STEPS.BILLING_DETAILS && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(ROUTES.PROFILE);
                      }}
                    >
                      {t('Profile')}
                    </MenuItem>
                  )}
                  {/* <MenuItem onClick={() => handleClose('')}>My account</MenuItem> */}
                  <MenuItem onClick={() => setWarning(true)}>
                    {t('logout')}
                  </MenuItem>
                </Menu>
              )}
            </div>
            {location.pathname === ROUTES.BUSINESSINFO ? null : (
              <div className="mobile_menu">
                <IconButton
                  color="primary"
                  onClick={() => {
                    store.dispatch(setCommonMenu(true));
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}
      </header>
      <WarningDialog
        open={showWarning}
        showIcon={false}
        handleClose={() => {
          setWarning(false);
        }}
        onClick={() => {
          if (loading) return;
          handleClose();
          logoutCall();
          resetAuthorizationToken();
        }}
        cancelBtnText={tC('btnTxt.cancel')}
        confirmBtnText={tC('btnTxt.log_out')}
        title={t('logout')}
        description={t('are_you_sure_you_want_to_logout')}
        type="warning"
      />

      {'safari' in window && 'pushNotification' in window.safari && (
        <PushNotification />
      )}
    </>
  );
}
