import UnderDevelopment from '../../Components/UnderDevelopment';

export default function UnderDevelopmentPage() {
  return (
    <div className="main">
      <UnderDevelopment
        heading="Under Development"
        title="This feature is under development"
        buttonText="Go to home"
        handleClick={() => {}}
        showButton={false}
      />
    </div>
  );
}
