import React, { useEffect, useState } from 'react';
import NoInternet from '../Components/NoInternet';
import Images from '../Utils/images';
import { AIT } from '../Utils/Localization';

const NetworkStatus: React.FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, []);

  if (!isOnline) {
    return (
      <div className="main">
        <header className="header">
          <div
            className="logo"
            // onClick={() => {
            //   status ? navigate(ROUTES.DASHBOARD) : navigate(ROUTES.LOGIN);
            // }}
          >
            <img src={Images.LOGO} alt={AIT().logo} />
          </div>
        </header>
        <NoInternet
          heading="No Internet Connection"
          title="The features in this area require Internet connectivity. Please connect your computer to the Internet and try again."
          buttonText="Refresh"
          handleClick={() => window.location.reload()}
          showButton={true}
        />
      </div>
    );
  }

  return <>{children}</>;
};

export default NetworkStatus;
