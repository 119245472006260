import { Suspense, useEffect } from 'react';
import {
  Route,
  // useNavigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import { pageRoutes } from './routes';
import { routeTypes } from '../types';
import PrivateRoute from './PrivateRoute';
import Header from '../Containers/Header';
import Footer from '../Containers/Footer';
import Loading from '../Components/Loading';
import PageNotFound from '../Components/PageNotFound';
import ErrorBoundary from '../Components/ErrorBoundary';
import NotificationService from '../Components/NotificationService';
import { useTranslation } from 'react-i18next';

function RoutesWrapper() {
  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <ErrorBoundary>
            <NotificationService />
            <Outlet />
          </ErrorBoundary>
        }
      >
        {pageRoutes.map((route: routeTypes) => {
          if (route.isPrivate) {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <PrivateRoute route={route}>
                    <div className="main">
                      <Header />
                      <div className="mainContainer" id="main_container">
                        <route.Component {...route.pageProp} />
                      </div>
                      <Footer />
                    </div>
                  </PrivateRoute>
                }
              />
            );
          }
          return (
            <Route
              key={route.id}
              path={route.path}
              element={<route.Component {...route.pageProp} />}
            />
          );
        })}
        <Route path="/payment" element={<Loading type="div" height="full" />} />
        <Route
          path="*"
          element={
            <div className="main">
              <Header />
              <PageNotFound
                showImage={true}
                heading={tC('msgs.err.pageNotFound')}
                title={tC('msgs.err.pageNotFoundTitle')}
                buttonText={tC('msgs.err.goToHome')}
                showButton={true}
                handleClick={() => {
                  window.location.href = '/';
                }}
              />
            </div>
          }
        />
      </Route>,
    ),
  );

  return (
    <Suspense
      fallback={<Loading type="div" height="full" className="routeContainer" />}
    >
      <RouterProvider router={router} />
    </Suspense>
  );
  {
    /* </Routes>
    </Suspense> */
  }
  // )
}

export default RoutesWrapper;

// export const router = createBrowserRouter([
//   {
//     id: 'root',
//     path: '/',
//     Component: AuthLayout,
//     children: [
//       {
//         index: true,
//         Component: PublicPage,
//       },
//       {
//         path: 'login',
//         Component: Login,
//       },
//       {
//         path: 'register',
//         Component: Register,
//       },
//       {
//         path: 'verify',
//         Component: VeriftEmail,
//       },
//     ],
//   },
// ])
