import {
  Action,
  AnyAction,
  Reducer,
  configureStore,
  createSelector,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk';
import { resetAuthorizationToken } from '../api';
import { rootReducer } from './rootReducer';
import { MessageType } from '../types';
import { CHAT_ACTIVE, CHAT_FAILED, CHAT_UPLOADING } from '../helpers/contants';

const reducerProxy: Reducer = (state: RootState, action: AnyAction) => {
  // on logout reset redux state
  if (action.type === 'auth/logout') {
    localStorage.removeItem('token');
    resetAuthorizationToken();
    return rootReducer({} as RootState, action);
  }
  return rootReducer(state, action);
};

const persistConfig = {
  debug: false,
  key: 'root',
  keyPrefix: 'v.1',
  storage,
  blacklist: [],
  whitelist: ['auth'],
};
const persistedReducer = persistReducer(persistConfig, reducerProxy);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      thunk as ThunkMiddleware,
    ),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
const stateSelector = (state: RootState) => state;
export const messagesSelector = createSelector(
  stateSelector,
  (state: RootState) => {
    const currentMessages: MessageType[] = state.chat.activeChatId
      ? state.chat.messages.messagesMap[`${state.chat.activeChatId}`]
      : [];
    return (
      currentMessages?.filter((message: MessageType) =>
        [CHAT_ACTIVE, CHAT_UPLOADING, CHAT_FAILED].includes(
          `${message.status}`,
        ),
      ) || []
    );
  },
);
export const currentActiveChat = createSelector(
  stateSelector,
  (state: RootState) => {
    return state.chat.activeChatId &&
      state.chat.chatMap[state.chat.activeChatId]
      ? state.chat.chatMap[state.chat.activeChatId]
      : {};
  },
);

export default store;
