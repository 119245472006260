import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import RoutesWrapper from './Router';
import store, { persistor } from './redux/store';
import './styles/index.scss';
import { SocketProvider } from './Pages/Chats/SocketContext';
import ErrorBoundary from './Components/ErrorBoundary';
import { tranlate } from './LocalizationJson/i18';
import NetworkStatus from './Containers/NetworkCheck';
import { THEME_OPTIONS } from './Utils/constantData';

// Access the process mode
const mode = process.env.NODE_ENV;

// Now you can use the mode variable in your application logic
if (mode === 'production') {
  console.log = console.warn = console.error = () => {};
}

export const App = () => {
  const theme = createTheme(THEME_OPTIONS);

  tranlate;
  return (
    <NetworkStatus>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <SocketProvider>
              <PersistGate loading={null} persistor={persistor}>
                <RoutesWrapper />
              </PersistGate>
              <ToastContainer />
            </SocketProvider>
          </Provider>
        </ThemeProvider>
      </ErrorBoundary>
    </NetworkStatus>
  );
};
