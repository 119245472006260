import { bool, boolean } from 'yup';
import Images from '../Utils/images';

interface Props {
  isLoading?: boolean;
}
export default function LoadingNotification(props: Props) {
  const { isLoading } = props;
  return (
    <div
      className={`success no_notification ${isLoading ? 'isLoading' : null}`}
    >
      <img
        src={Images.LOADER_TWO}
        alt={'Loading, Please wait'}
        style={{
          width: '60px',
          margin: isLoading ? 0 : '40px 0',
        }}
      />
      {isLoading ? null : <h3>Loading, Please wait</h3>}
    </div>
  );
}
