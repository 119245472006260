//Alt Image Text
export const AIT = (str: string = '') => {
  return {
    logo: 'Logo',
    typeImage: `${str}`,
    checkbox: 'Checkbox',
    slider: `Slider ${str}`,
    close: 'Close',
    icon: 'Icon',
    edit: 'Edit',
    uploaderPlaceholder: 'Uploader Placeholder',
    infoIcon: 'Info',
    loading: 'Loading',
    delete: 'Delete',
    notification: 'Notification',
    notifications: 'Notifications',
    user: 'User',
    search: 'Search',
    location: 'Location',
    proceed: 'Proceed',
    cancel: 'Cancel',
    download: 'Download',
    removeImg: 'Remove image',
    banner: 'Banner',
    backgroundImg: 'Background Image',
    showPswrd: 'Show Password',
    hidePswrd: 'Hide Password',
    productImg: 'Product Image',
    call: 'Call',
    mail: 'Mail',
    headquater: 'Headquarter',
    welcome: 'Welcome',
    warning: 'Warning',
  };
};

//Localization
export const l = (key: string) => {
  const eng: { [key: string]: string } = {
    s: 's',
  };
  return eng[key];
};
