import auth from './auth.slice';
import branch from './branches.slice';
import { coVendorReducer } from './coVendor.slice';
import common from './common.slice';
import company from './company.slice';
import product from './products.slice';
import staticData from './staticContent.slice';
import order from './order.slice';
import notification from './notification.slice';
import { combineReducers } from '@reduxjs/toolkit';
import { chatReducer } from './chat.slice';
import client from './client.slice';
import riskMngslice from './riskMngslice';

export const rootReducer = combineReducers({
  auth,
  common,
  product,
  staticData,
  branch,
  coVendor: coVendorReducer,
  company,
  order,
  chat: chatReducer,
  notification,
  client,
  riskMngslice,
});
