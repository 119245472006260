import store, { RootState } from './store';
import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import {
  apiSuccessCode,
  deleteApiCall,
  getApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import { ApiStatus, BranchState, CrudOperation, TableAction } from '../types';
import { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';

const initialState: BranchState = {
  branches: [],
  loading: false,
  error: false,
  page: 1,
  selectedIds: [],
  searchKeywords: '',
  filter: null,
  branchData: null,
  existedZone: [],
  actionApiStatus: ApiStatus.idle,
};

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setList: (state, action) => {
      const { payload }: any = action;
      state.branches = payload;
    },
    setLoader: (state, action) => {
      const { payload }: any = action;
      state.loading = payload;
    },
    setError: (state, action) => {
      const { payload }: any = action;
      state.error = payload;
    },
    setPage: (state, action) => {
      const { payload }: any = action;
      state.error = payload;
    },
    setSelected: (state, action) => {
      const { payload }: any = action;
      state.selectedIds = payload;
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action;
      state.searchKeywords = payload;
    },
    setFilters: (state, action) => {
      const { payload }: any = action;
      state.filter = payload;
    },
    setBranchDetail: (state, action) => {
      const { payload }: any = action;
      state.branchData = payload;
    },
    updateActionApiStatus: (state, action) => {
      const { payload }: any = action;
      state.actionApiStatus = payload;
    },
    setExistedZone: (state, action) => {
      const { payload }: any = action;
      state.existedZone = payload;
    },
  },
});

export const {
  setList,
  setLoader,
  setError,
  setPage,
  setSelected,
  setSearchKeywords,
  setFilters,
  setBranchDetail,
  updateActionApiStatus,
  setExistedZone,
} = branchSlice.actions;

export default branchSlice.reducer;

const actionLoader = (flag: ApiStatus) => {
  const { dispatch } = store;
  dispatch(updateActionApiStatus(flag));
};

const handleError = (e: any) => {
  const { dispatch } = store;
  actionLoader(ApiStatus.failed);
  if (e.data && e.data.message) {
    notify(e.data.message, 'error');
  } else {
    notify(null, 'error');
  }
};

export const getAndSetBranchList = (
  query: string = '?pageNo=1&limit=10',
): any => {
  const { searchKeywords: searchKeyword, loading } = store.getState().branch;

  const searchQuery = searchKeyword
    ? `&searchKey=${encodeURIComponent(searchKeyword)}`
    : '';
  const filters = store.getState().branch.filter;
  let filterQuery = '';

  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate() + 2);
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.status) {
      filterQuery += `&status=${filters.status.toUpperCase()}`;
    }
  }
  const params = `${query}${searchQuery}${filterQuery}`;
  const apiEndpoint = endPoints.branch.CRUD;
  store.dispatch(setLoader(true));

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        store.dispatch(setList(data.data));
      }
      store.dispatch(setLoader(false));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching branches list`, 'error');
      }

      store.dispatch(setLoader(false));
    },
  );
};

export const getBranchDetails = (id: string): any => {
  const { dispatch } = store;
  const apiEndpoint = endPoints.branch.CRUD;
  dispatch(setLoader(true));
  getApiCall(
    `${apiEndpoint}/${id}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setBranchDetail(data.data));
      }
      dispatch(setLoader(false));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching branches list`, 'error');
      }

      dispatch(setLoader(false));
    },
  );
};

export const addOrUpdateBranch = (data: any, type: string, cb: () => void) => {
  const { dispatch, getState } = store;
  const {
    branch: { branchData },
  }: RootState = getState();
  actionLoader(ApiStatus.loading);
  data['managers'] = data['managers'].map((m: any) => {
    if (m['lastName'] == null) {
      delete m['lastName'];
    }
    return m;
  });
  if (type === 'EditBranch') {
    const keysToDelete = ['products', 'managers', 'isAllProducts'];
    keysToDelete.forEach((k) => {
      delete data[k];
    });

    putApiCall(
      `${endPoints.branch.CRUD}/${branchData._id}`,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.accepted ||
            statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.success)
        ) {
          cb();
        }
        actionLoader(ApiStatus.succeeded);
      },
      (e: any) => {
        handleError(e);
      },
    );
  } else {
    postApiCall(
      endPoints.branch.CRUD,
      data,
      (s: any) => {
        const {
          data: { statusCode },
        } = s;
        if (
          data &&
          statusCode &&
          (statusCode === apiSuccessCode.accepted ||
            statusCode === apiSuccessCode.created ||
            statusCode === apiSuccessCode.success)
        ) {
          cb();
        }
        actionLoader(ApiStatus.succeeded);
      },
      (e: any) => {
        handleError(e);
      },
    );
  }
};

export const managerCRUD = (
  type: CrudOperation,
  data: any,
  cb: (data: any) => void,
) => {
  switch (type) {
    case CrudOperation.delete:
      {
        const { branchId, managerId } = data;
        deleteApiCall(
          `${endPoints.branch.CRUD}/${branchId}/managers/${managerId}`,
          {},
          (d: any) => {
            cb(d);
          },
          (e: any) => {
            cb(e);
            handleError(e);
          },
        );
      }
      break;
    case CrudOperation.update:
      const { managerId, payload } = data;
      putApiCall(
        `${endPoints.branch.managerAddOrUpdate}/${managerId}`,
        payload,
        (d: any) => {
          cb(d);
        },
        (e: any) => {
          cb(e);
          handleError(e);
        },
      );
      break;
    case CrudOperation.create:
      postApiCall(
        `${endPoints.branch.managerAddOrUpdate}`,
        data,
        (d: any) => {
          cb(d);
        },
        (e: any) => {
          cb(e);
          handleError(e);
        },
      );
      break;
    default:
      break;
  }
};

export const branchAction = (
  type: TableAction,
  data: any,
  cb: (data: any) => any,
) => {
  const { dispatch } = store;
  actionLoader(ApiStatus.loading);
  switch (type) {
    case TableAction.status:
      {
        const { id, status } = data;
        putApiCall(
          `${endPoints.branch.CRUD}/${id}/status`,
          {
            status,
          },
          (d: any) => {
            if (d.data && d.data.statusCode === apiSuccessCode.success) {
              notify(
                `Branch status is ${
                  status === 'ACTIVE' ? 'Active' : 'Inactive'
                }.`,
                'success',
              );
              setTimeout(() => {
                actionLoader(ApiStatus.succeeded);
              }, 1000);
            }
          },
          (e: any) => {
            handleError(e);
          },
        );
      }
      break;
    case TableAction.delete: {
      const { branchToBeDeleted } = data;
      deleteApiCall(
        `${endPoints.branch.CRUD}/${branchToBeDeleted}`,
        {},
        (d: any) => {
          cb(d);
          actionLoader(ApiStatus.succeeded);
        },
        (e: any) => {
          handleError(e);
        },
      );
    }
    default:
      break;
  }
};

export const getAndSetExistedZones = (branchId: string | null = null) => {
  const { dispatch } = store;
  actionLoader(ApiStatus.loading);
  getApiCall(
    `${endPoints.branch.zones}${branchId ? `?branchId=${branchId}` : ''}`,
    (resp: AxiosResponse) => {
      const { data } = resp;
      if (data.data) {
        dispatch(setExistedZone(data.data.filter((zone: any) => zone.zone)));
      }
      actionLoader(ApiStatus.succeeded);
    },
    (e: AxiosError) => {
      handleError(e);
    },
  );
};
