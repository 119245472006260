import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import { getApiCall, putApiCall } from '../api/methods';
import { ApiStatus, CompanyState } from '../types';
import { logout } from './auth.slice';
import store, { RootState } from './store';

const initialState: CompanyState = {
  apiStatus: ApiStatus.idle,
  companyDetails: null,
};

const companySlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCompanyDetails: (state, action) => {
      const { payload }: any = action;
      state.companyDetails = payload;
    },
    updateApiStatus: (state, action) => {
      const { payload } = action;
      state.apiStatus = payload;
    },
  },
});

export const { updateApiStatus, setCompanyDetails } = companySlice.actions;

export default companySlice.reducer;

export const getAndSetCompanyDetails = () => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  getApiCall(
    endPoints.company.getOrPut,
    (e: any) => {
      const { data } = e;
      if (data && data.data) {
        const details = data.data;
        if (details) {
          dispatch(setCompanyDetails(details));
        }
      }
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching company details`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateApiStatus(ApiStatus.failed));
    },
  );
};

export const updateCompanyDetails = (data: any, cb: (data: any) => void) => {
  const { getState } = store;
  const {
    common: { departmentList },
  }: RootState = getState();
  data['department'] = departmentList
    .filter((d: any) => data['department'].includes(d.deptName))
    .map((d: any) => d._id);

  data['businessLocation'] = data['businessLocation'].name;
  data['countryOfIssue'] = data['countryOfIssue'].name;
  data['establishedYear'] = `${data['establishedYear']}`;
  delete data['document'];
  delete data['imgBaseUrl'];
  putApiCall(
    endPoints.company.getOrPut,
    data,
    (s: any) => {
      const { data } = s;
      cb(data);
    },
    (e: any) => {
      cb(e);
      if (e?.data && e?.data.message) {
        notify(e?.data.message, 'error');
      } else {
        notify('Error while updating, Please try again.', 'error');
      }
    },
  );
};
