import { lazy } from 'react';
const DashboardWrapper = lazy(() => import('../Pages/Dashboard'));
const AuthLayout = lazy(() => import('../Containers/AuthLayout'));
const ProductManagement = lazy(() => import('../Pages/ProductManagement'));
const Orders = lazy(() => import('../Pages/Orders'));
const Branches = lazy(() => import('../Pages/Branches'));
const Financials = lazy(() => import('../Pages/Financials'));
const Chats = lazy(() => import('../Pages/Chats'));
const Companies = lazy(() => import('../Pages/Company'));
const Clients = lazy(() => import('../Pages/Clients'));
const Profile = lazy(() => import('../Pages/Profile'));
const PublicPages = lazy(() => import('../Pages/PublicPage'));
const CoVendors = lazy(() => import('../Pages/Employ'));
const Drivers = lazy(() => import('../Pages/Drivers'));
const t = (k: string) => tranlate(k);
import BusinessInfo from '../Pages/Register/BusinessInfo';
import Welcome from '../Pages/Register/Welcome';
import { PAGE_MODES } from '../Utils/constantData';
import { ROUTES } from '../helpers/contants';
import { routeTypes } from '../types';
import { tranlate } from '../LocalizationJson/i18';
const RiskManagement = lazy(() => import('../Pages/RiskManagement'));
const GroupManagement = lazy(()=> import('../Pages/GroupsManagement'))
import UnderDevelopmentPage from '../Pages/UnderDevelopment/UnderDevelopment';

export const pageRoutes: routeTypes[] = [
  {
    id: 1,
    name: 'Dashboard',
    path: ROUTES.DASHBOARD,
    Component: DashboardWrapper,
    isPrivate: true,
  },
  {
    id: 2,
    name: 'Login',
    path: ROUTES.LOGIN,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Login' },
  },
  {
    id: 3,
    name: 'SignUp',
    path: ROUTES.SIGNUP,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'SignUp' },
  },
  {
    id: 4,
    name: 'Verify',
    path: ROUTES.VERIFY,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Verify' },
  },
  {
    id: 5,
    name: 'Forgot',
    path: ROUTES.FORGOT_PASSWORD,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Forgot' },
  },
  {
    id: 6,
    name: 'Reset',
    path: ROUTES.RESET_PASSWORD,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Reset' },
  },
  {
    id: 6,
    name: 'Welcome',
    path: ROUTES.WELCOME,
    Component: Welcome,
    isPrivate: true,
  },
  {
    id: 7,
    name: 'BusinessInfo',
    path: ROUTES.BUSINESSINFO,
    Component: BusinessInfo,
    isPrivate: true,
  },
  {
    id: 8,
    name: t('Product_Management.title'),
    path: ROUTES.PRODUCTS,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'ProductList' },
  },
  {
    id: 9,
    name: t('Product_Management.title'),
    path: ROUTES.ADD_PRODUCTS,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'AddProduct' },
  },
  {
    id: 9,
    name: t('Product_Management.title'),
    path: ROUTES.EDIT_PRODUCTS,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'EditProduct' },
  },
  {
    id: 10,
    name: t('Product_Management.title'),
    path: ROUTES.ADD_SERVICES,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'AddServices' },
  },
  {
    id: 11,
    name: t('Product_Management.title'),
    path: ROUTES.EDIT_SERVICE,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'EditService' },
  },
  {
    id: 12,
    name: t('orders.title'),
    path: ROUTES.ORDERS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'Orders' },
  },
  {
    id: 13,
    name: 'Branch Management',
    path: ROUTES.BRANCHES,
    Component: Branches,
    isPrivate: true,
    pageProp: { page: 'Branches' },
  },
  {
    id: 14,
    name: 'Financials',
    path: ROUTES.FINANCIALS,
    Component: Financials,
    isPrivate: true,
    pageProp: { page: 'Financials' },
  },
  {
    id: 15,
    name: 'Chats',
    path: ROUTES.CHATS,
    Component: Chats,
    isPrivate: true,
    pageProp: { page: 'Chats' },
  },
  {
    id: 16,
    name: 'Company Management',
    path: ROUTES.COMPANY,
    Component: Companies,
    isPrivate: true,
    pageProp: { page: 'AddCompany' },
  },
  {
    id: 17,
    name: 'Client Management',
    path: ROUTES.CLIENTS,
    Component: Clients,
    isPrivate: true,
    pageProp: { page: 'Clients' },
  },
  {
    id: 18,
    name: 'UnderDevelopment',
    path: ROUTES.UNDERDEVELOPMENT,
    Component: UnderDevelopmentPage,
    isPrivate: false,
    pageProp: { page: 'UnderDevelopment' },
  },
  {
    id: 19,
    name: 'Profile',
    path: ROUTES.PROFILE,
    Component: Profile,
    isPrivate: true,
    pageProp: { page: 'Profile' },
  },
  {
    id: 20,
    name: 'Policies',
    path: ROUTES.POLICIES,
    Component: PublicPages,
    isPrivate: false,
    pageProp: { page: 'privacyPolicy' },
  },
  {
    id: 21,
    name: 'Faq',
    path: ROUTES.FAQ,
    Component: PublicPages,
    isPrivate: false,
    pageProp: { page: 'Faq' },
  },
  {
    id: 22,
    name: 'Support',
    path: ROUTES.SUPPORT,
    Component: PublicPages,
    isPrivate: false,
    pageProp: { page: 'support' },
  },
  {
    id: 23,
    name: 'Branch Management',
    path: ROUTES.ADD_BRANCH,
    Component: Branches,
    isPrivate: true,
    pageProp: { page: 'AddBranch' },
  },
  {
    id: 24,
    name: 'Branch Management',
    path: ROUTES.EDIT_BRANCH,
    Component: Branches,
    isPrivate: true,
    pageProp: { page: 'EditBranch' },
  },
  {
    id: 25,
    name: 'Branch Management',
    path: ROUTES.VIEW_BRANCH,
    Component: Branches,
    isPrivate: true,
    pageProp: { page: 'ViewBranch' },
  },
  {
    id: 26,
    name: 'Terms',
    path: ROUTES.TERMS,
    Component: PublicPages,
    isPrivate: false,
    pageProp: { page: 'termsAndConditions' },
  },

  {
    id: 27,
    name: t('Employ_Management.pageName'),
    path: ROUTES.EMPLOY,
    Component: CoVendors,
    isPrivate: true,
    pageProp: { page: PAGE_MODES.LIST },
  },
  {
    id: 28,
    name: t('Employ_Management.pageName'),
    path: ROUTES.ADD_EMPLOY,
    Component: CoVendors,
    isPrivate: true,
    pageProp: { page: PAGE_MODES.ADD },
  },
  {
    id: 29,
    name: t('Employ_Management.pageName'),
    path: ROUTES.EDIT_EMPLOY,
    Component: CoVendors,
    isPrivate: true,
    pageProp: { page: PAGE_MODES.EDIT },
  },
  {
    id: 30,
    name: t('Employ_Management.pageName'),
    path: ROUTES.VIEW_EMPLOY,
    Component: CoVendors,
    isPrivate: true,
    pageProp: { page: PAGE_MODES.VIEW },
  },
  {
    id: 31,
    name: t('orders.title'),
    path: ROUTES.ORDERS_DETAILS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'OrderDetails' },
  },
  {
    id: 32,
    name: t('orders.title'),
    path: ROUTES.ORDERS_WORKSHEET,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'OrderWorksheet' },
  },
  {
    id: 33,
    name: 'Chats',
    path: ROUTES.ORDER_SERVICES,
    Component: Chats,
    isPrivate: true,
    pageProp: { page: 'Orders' },
  },
  {
    id: 34,
    name: 'Chats',
    path: ROUTES.CREATE_ORDER,
    Component: Chats,
    isPrivate: true,
    pageProp: { page: 'CreateOrder' },
  },
  {
    id: 35,
    name: t('orders.title'),
    path: ROUTES.ORDERS_PRODUCTS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'OrderProducts' },
  },
  {
    id: 36,
    name: t('orders.title'),
    path: ROUTES.ADD_PRODUCTS_ORDERS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'AddProducts' },
  },
  {
    id: 37,
    name: 'Chats',
    path: ROUTES.EDIT_ORDER,
    Component: Chats,
    isPrivate: true,
    pageProp: { page: 'EditOrder' },
  },
  {
    id: 38,
    name: 'Financials',
    path: ROUTES.FINANCIAL_DETAIL,
    Component: Financials,
    isPrivate: true,
    pageProp: { page: 'FinancialDetail' },
  },
  {
    id: 39,
    name: 'Client Management',
    path: ROUTES.CLIENTDETAILS,
    Component: Clients,
    isPrivate: true,
    pageProp: { page: 'ClientDetails' },
  },
  {
    id: 39,
    name: 'Client Management',
    path: ROUTES.CLIENTSETDETAILS,
    Component: Clients,
    isPrivate: true,
    pageProp: { page: 'ClientSetDetails' },
  },
  {
    id: 40,
    name: 'Driver Management',
    path: ROUTES.DRIVERS,
    Component: Drivers,
    isPrivate: true,
    pageProp: { page: 'Drivers' },
  },
  {
    id: 40,
    name: 'Driver Management',
    path: ROUTES.DRIVER_VIEW,
    Component: Drivers,
    isPrivate: true,
    pageProp: { page: 'DriverDetail' },
  },
  {
    id: 41,
    name: 'Driver Management',
    path: ROUTES.DRIVER_ADD,
    Component: Drivers,
    isPrivate: true,
    pageProp: { page: 'DriverAdd' },
  },
  {
    id: 42,
    name: 'Driver Management',
    path: ROUTES.VEHICLE_VIEW,
    Component: Drivers,
    isPrivate: true,
    pageProp: { page: 'VehicleDetail' },
  },
  {
    id: 43,
    name: 'Driver Management',
    path: ROUTES.VEHICLE_ADD,
    Component: Drivers,
    isPrivate: true,
    pageProp: { page: 'VehicleAdd' },
  },
  {
    id: 44,
    name: t('Product_Management.title'),
    path: ROUTES.ADD_PACKAGE,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'AddPackage' },
  },
  {
    id: 45,
    name: t('Product_Management.title'),
    path: ROUTES.EDIT_PACKAGE,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'EditPackage' },
  },
  {
    id: 42,
    name: t('Product_Management.title'),
    path: ROUTES.ADD_VARIANT,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'AddVariant' },
  },
  {
    id: 43,
    name: t('Product_Management.title'),
    path: ROUTES.EDIT_VARIANT,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'EditVariant' },
  },
  {
    id: 44,
    name: t('Product_Management.title'),
    path: ROUTES.ADD_SERVICE_VARIANT,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'Add_Service_variant' },
  },
  {
    id: 45,
    name: t('Product_Management.title'),
    path: ROUTES.EDIT_SERVICE_VARIANT,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'Add_Service_variant' },
  },
  {
    id: 46,
    name: t('orders.title'),
    path: ROUTES.DRAFT_DETAILS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'Draft_Details' },
  },
  {
    id: 45,
    name: 'Risk Management',
    path: ROUTES.RISK_MANAGEMENT,
    Component: RiskManagement,
    isPrivate: true,
    pageProp: { page: 'RiskManagement' },
  },
  {
    id: 46,
    name: 'Risk Management',
    path: ROUTES.VIEW_RISK_USER,
    Component: RiskManagement,
    isPrivate: true,
    pageProp: { page: 'ViewUser' },
  },
  {
    id: 47,
    name: 'Risk Management',
    path: ROUTES.ADD_RISK_USER,
    Component: RiskManagement,
    isPrivate: true,
    pageProp: { page: 'AddUser' },
  },
  {
    id: 48,
    name: 'Risk Management',
    path: ROUTES.EDIT_RISK_USER,
    Component: RiskManagement,
    isPrivate: true,
    pageProp: { page: 'EditUser' },
  },
  {
    id: 49,
    name: 'Groups Management',
    path: ROUTES.GROUPS,
    Component: GroupManagement,
    isPrivate: true,
    pageProp: { page: 'GroupsList' },
  },
];
